.box-image {
  padding: 0;
}

.box-insider.box-image {
  padding: 1rem;
}

.mask {
  background-color: rgba(0, 0, 0, .7);
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.episode-wrapper {
  padding: 0 20px;
}

.live-segment-wrapper {
  padding-bottom: 20px;
}

.episode-title-wrapper {
  padding: 20px;
}

.episode-page-header {
  padding-bottom: 0;
}

.episode-number-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.episode-number-wrapper p {
  margin-bottom: 0;
}

.episode-number-wrapper input.ant-input {
  height: 24px;
}

.episode-wrapper .description {
  background: transparent;
  margin: 1em;
}

.episode-wrapper .description-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.3em;
}

/* .episode-list{
  max-height: calc(100vh - 141px - 105px - 64px - 50px);
  overflow: auto;
  padding: 20px;
	padding-top: 0;
}
@media(max-width: 1200px){
  .episode-list{
    max-height: calc(100vh - 141px - 105px - 64px - 80px);
  }
} */
.episode-list-wrapper {
  max-height: calc(100vh - 97px - 64px);
  overflow: auto;
}

.add-episode-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DDDDDD;
  border-radius: 5px;
  max-height: 150px;
  min-height: 120px;
  height: 150px;
  cursor: pointer;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.add-episode-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

@media(max-width: 767px) {
  .add-episode-wrapper {
    margin-bottom: 2rem;
  }
}

.episode-card-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.episode-card-wrapper .ant-tag {
  position: absolute;
  right: 0;
  top: 10px;
}

.clips-card-col-wrapper {
  margin-bottom: 24px;
}

@media(min-width: 991px) {
  .clips-card-col-wrapper.ant-col-xl-6:nth-child(n+5) {
    margin-top: 40px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .clips-card-col-wrapper.ant-col-xl-6:nth-child(n+4) {
    margin-top: 40px;
  }
}

@media(max-width: 767px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .clips-card-col-wrapper.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

.episode-card img {
  max-width: 100%;
  border-radius: 5px;
  /* width: 300px; */
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.episode-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

.episode-details {
  margin-left: 0.3em;
}

.episode-title {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0;
}

.episode-date {
  color: rgb(39, 40, 50);
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.episode-description-div-wrapper {
  margin: 0px 20px;
}

.episode-description-div-wrapper .episode-card {
  display: flex;
  align-items: center;
  height: 100%;
}

.episode-description-wrapper {
  padding: 0px 20px;
}

.episode-description-wrapper .episode-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  object-fit: cover;
}

.episode-description-wrapper .episode-description {
  /* height: 100%; */
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  /* word-break: break-all; */
}

.episode-description-wrapper .featured-image-link {
  text-decoration: underline;
  cursor: pointer;
}

.episode-wrapper .title-wrapper h4 {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px;
}

.episode-wrapper .segmenet-title {
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .box-image .segmenet-title {
  color: white;
}

.episode-wrapper .upload-image-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .upload-image-wrapper button {
  background: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid;
  padding: 0;
  border-radius: 0;
}

.dropzone-wrapper {
  margin-bottom: 2em;
}

.dropzone-wrapper .ant-col {
  padding-left: 24px;
  padding-right: 24px;
}

.episode-wrapper .box-image .video-uploader .ant-progress-text,
.episode-wrapper .box-image .audio-uploader .ant-progress-text {
  color: white;
}

.episode-wrapper .video-uploader .ant-progress-text,
.episode-wrapper .audio-uploader .ant-progress-text {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card {
  background: transparent;
  width: 80%;
  margin: 0 auto;
  height: 180px;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .upload-image-wrapper button {
  color: white;
  font-weight: 600;
}

.episode-wrapper .play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  min-height: 180px;
  border: 1px dashed;
  border-width: 1.8px;
  border-color: #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.episode-wrapper .play-button span {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .box-image .play-button span,
.episode-wrapper .box-image .play-button button span,
.episode-wrapper .play-button button span {
  color: #fff;
}

.episode-wrapper .box-image .play-button {
  border-color: white;
}

.episode-wrapper .play-button .remove {
  position: absolute;
  top: -18px;
  right: -14px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .play-button.download-play-wrapper {
  flex-direction: column;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card:hover,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card:hover {
  border-width: 1.8px;
  border-color: white;
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card i,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card i {
  font-size: 38px;
  margin-bottom: 0.3em;
}

.episode-wrapper .parts-lists h4 {
  margin-bottom: 0;
}

.episode-wrapper .parts-lists .ant-list-item-meta-description {
  font-size: 12px;
}

.episode-wrapper .parts-lists i {
  font-size: 20px;
}

.episode-wrapper .parts-lists .ant-list-item {
  cursor: pointer;
  padding: 0.7em 1em;
  border-radius: 2px;
  min-height: 50px;
}

.episode-wrapper .parts-lists .ant-list-item.active {
  background: #e8e8e8;
}

.episode-wrapper .parts-lists .ant-list-item.uploading,
.add-segment-button.uploading {
  pointer-events: none;
}

.segment-live-table .ant-table-title {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
}

.segment-live-table i {
  cursor: pointer;
  font-size: 16px;
  transition: all .4s ease;
  color: #1890ff;
  margin-left: 5px;
}

.segment-live-table i:hover {
  transform: translate3D(0, -1px, 0) scale(1.3);
  transition: all .4s ease;
}

.episode-wrapper .episode-live-details div {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.episode-wrapper .episode-live-details div span {
  font-weight: normal;
  margin-left: 0.3rem;
  word-break: break-all;
  /* color: #2094ff; */
}

.add-episode-form .ant-progress-outer {
  display: none;
}

.segment-more-button:hover {
  visibility: visible !important;
}

.episode-wrapper .ant-table-row:nth-child(even),
.episode-wrapper .ant-table-row:nth-child(odd),
.episode-wrapper .ant-table-thead>tr>th {
  background: transparent !important;
}

.episode-wrapper td:last-child {
  word-break: break-all;
}

.episode-wrapper .player-section-wrapper .box {
  height: 100%;
}

.episode-wrapper .player-section-wrapper,
.episode-wrapper .live-details-section-wrapper {
  padding-bottom: 20px;
}

@media(max-width: 991px) {
  .episode-wrapper .player-section-wrapper {
    width: 100%;
    margin-top: 30px;
  }

  .segment-details-wrapper {
    margin-top: 30px;
  }
}

.segment-details-wrapper .box,
.segment-lists-wrapper .box {
  height: 100%;
}

.episode-wrapper .ant-upload-list.ant-upload-list-picture {
  display: none;
}

.segment-live-image-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.segment-description {
  word-break: break-all;
  margin-top: 10px;
}

/* .live-player .video-react-time-control, .live-player .video-react-progress-control, .live-player .video-react .video-react-progress-control {
  display: none !important;
} */