.person-management-wrapper {
  padding: 20px;
  height: 100%;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.title-wrapper h2.ant-typography {
  margin-bottom: 0;
}

.person-management-wrapper .profileImage-wrapper {
  text-align: center;
}

.person-management-wrapper .profileImage-wrapper img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.person-management-wrapper .ant-upload-select-picture {
  border-radius: 50%;
}

.person-management-wrapper .ant-upload-list-item-uploading {
  display: none;
}

.person-management-wrapper .ant-form-item-control {
  line-height: 30px;
}

/* .action-icons .anticon.anticon-edit, .action-icons .anticon.anticon-stop, .action-icons .anticon.anticon-sync{
} */
.action-icons .anticon.anticon-stop,
.action-icons .anticon.anticon-sync,
.action-icons .anticon.anticon-key,
.action-icons .anticon.anticon-check-circle {
  margin-left: 6px;
}

.action-icons .anticon.anticon-stop {
  font-size: 14px;
  color: #F44336;
}

.action-icons .anticon.anticon-check-circle {
  font-size: 15px;
}

.action-icons .anticon.anticon-sync {
  font-size: 14px;
  color: #1890ff;
}

.person-management-wrapper .ant-pagination {
  text-align: right;
  margin-top: 20px;
}

.is-inactive {
  background-color: #ffefef !important
}
.is-inactive:hover {
  background-color: #ffefef !important
}

.email-link-button {
  padding: 0!important;
}

.person-management-wrapper .page-change-btn-wrapper {
  float: right;
}