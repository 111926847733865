.show-carousel-wrapper {
  max-width: 100%;
}

.show-carousel-landscape-card-wrapper {
  position: relative;
  max-width: 100%;
  height: 150px;
  width: 300px;
}

.show-carousel-portrait-card-wrapper {
  position: relative;
  max-width: 100%;
  width: 127px;
  height: 227px;
}

.show-carousel-card img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  max-width: 100%;
  max-height: 225px;
  min-height: 225px;
}

.show-carousel-card-delete {
  position: absolute;
  right: 10px;
  z-index: 111;
  top: 8px;
}

.show-carousel-lanscape-detail {
  width: 100%;
}

.show-carousel-lanscape-title {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-carousel-title {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 24px;
}

.show-carousel-detail {
  text-align: left;
}

.show-carousel-card-col-wrapper {
  margin-bottom: 24px;
}

.show-carousel-portrait-card-col-wrapper {
  margin-bottom: 60px;
}

@media (min-width: 1200) {
  .show-carousel-portrait-card-col-wrapper:nth-child(n + 9) {
    margin-top: 60px;
  }
}

@media (min-width: 992px) and (max-width: 1199) {
  .show-carousel-card-col-wrapper.ant-col-xl-6:nth-child(n + 5) {
    margin-top: 40px;
  }
  .show-carousel-portrait-card-col-wrapper:nth-child(n + 8) {
    margin-top: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .show-carousel-card-col-wrapper.ant-col-xl-6:nth-child(n + 4) {
    margin-top: 20px;
  }
  .show-carousel-portrait-card-col-wrapper:nth-child(n + 5) {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .show-carousel-card-col-wrapper.ant-col-xl-6:nth-child(n + 2) {
    margin-top: 20px;
  }
  .show-carousel-portrait-card-col-wrapper:nth-child(n + 2) {
    margin-top: 60px;
  }
}
