.discussions-wrapper{
  padding: 20px;
}
.discussions-detailpage-wrapper{
  height: 100%;
}
.title-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.title-wrapper h2.ant-typography{
  margin-bottom: 0;
}
.discussions-subject{
  color: rgba(0, 0, 0, 0.65);
}
.discussions-detailpage-wrapper .discussions-description{
  margin: 20px 0;
  margin-bottom: 0;
  max-height: 130px;
}
.discussions-detailpage-wrapper .discussions-description .description-content{
  height: 80px;
  max-height: 80px;
  overflow: auto;
}
.discussions-image{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.discussions-image p{
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}
.discussions-wrapper .ant-calendar-picker-input.ant-input{
  padding: 4px;
  font-size: 13px;
}
.discussions-wrapper .ant-table-row td:first-child, .discussions-wrapper .ant-table-row td:first-child:hover a {
  cursor: pointer;
  color: #40a9ff;
}
.discussions-wrapper .ant-pagination{
  text-align: right;
  margin-top: 20px;
}

.discussions-wrapper .page-change-btn-wrapper {
  float: right;
  margin-top: 10px;
}

.discussions-wrapper .link-button {
  padding: 0;
}
.discussions-wrapper .link-button-icon {
  font-size: 14px; 
  margin-left: 8px;
}
.discussions-wrapper .check-cirlce-icon {
  font-size: 18px;  
}
.discussions-wrapper .close-button-icon {
  font-size: 18px;   
  color: #F44336;
}
/* .fullscreen.fullscreen-enabled .discussions-wrapper .comments-filter-dropdown{
  display: none;
} */
