.user-management-wrapper {
  padding: 20px;
  height: 100%;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.title-wrapper h2.ant-typography {
  margin-bottom: 0;
}

.user-management-wrapper .profileImage-wrapper {
  text-align: center;
}

.user-management-wrapper .profileImage-wrapper img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.user-management-wrapper .ant-upload-select-picture {
  border-radius: 50%;
}

.user-management-wrapper .ant-upload-list-item-uploading {
  display: none;
}

.user-management-wrapper .ant-form-item-control {
  line-height: 30px;
}

.user-management-wrapper .ant-pagination {
  text-align: right;
  margin-top: 20px;
}
.is-inactive {
  background-color: #ffefef !important
}
.is-inactive:hover {
  background-color: #ffefef !important
}

.email-link-button {
  padding: 0!important;
}

.user-management-wrapper .page-change-btn-wrapper {
  float: right;
}