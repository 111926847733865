.comments-wrapper {
  padding: 20px;
  border-top: 1px solid silver;
}
.episode-wrapper .comments-wrapper {
  border: 0;
}
.live-segment-wrapper .comments-wrapper {
  padding: 20px 0;
}
.comments-wrapper .comments-scroller {
  position: relative;
  /* height: calc(100vh - 233px - 42px - 88px - 130px); */
  height: calc(100vh - 233px - 42px - 65px - 22px);
  overflow: auto;
  margin-bottom: 10px;
  /* scroll-behavior: smooth; */
  z-index: 1;
}
.fullscreen.fullscreen-enabled .comments-wrapper .comments-scroller {
  height: calc(100vh - 233px - 42px - 22px);
}
.comments-wrapper .comments-scroller.enable-reply {
  height: calc(100vh - 233px - 42px - 65px - 31px - 22px);
}
.comments-wrapper .comments-scroller .discussions-image {
  height: calc(100vh - 233px - 42px - 65px - 22px);
}
.comments-wrapper .comments-scroller.not-live,
.comments-wrapper .comments-scroller.not-live .discussions-image {
  height: calc(100vh - 233px - 42px - 36px - 130px);
}
.comments-wrapper .comments-scroller.not-live.no-description,
.comments-wrapper
  .comments-scroller.not-live.no-description
  .discussions-image {
  height: calc(100vh - 233px - 42px - 16px);
}
.fullscreen.fullscreen-enabled .comments-wrapper .comments-scroller.not-live,
.fullscreen.fullscreen-enabled
  .comments-wrapper
  .comments-scroller.not-live
  .discussions-image {
  height: calc(100vh - 233px - 42px - 90px);
}
.fullscreen.fullscreen-enabled
  .comments-wrapper
  .comments-scroller.not-live.no-description,
.fullscreen.fullscreen-enabled
  .comments-wrapper
  .comments-scroller.not-live.no-description
  .discussions-image {
  height: calc(100vh - 180px);
}
.livechat-detailpage-wrapper .comments-wrapper .comments-scroller,
.livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller
  .discussions-image {
  height: calc(100vh - 245px - 50px - 22px);
}

.fullscreen.fullscreen-enabled
  .livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller,
.fullscreen.fullscreen-enabled
  .livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller
  .discussions-image {
  height: calc(100vh - 245px - 22px);
}
.livechat-detailpage-wrapper .comments-wrapper .comments-scroller.not-live,
.livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller.not-live
  .discussions-image {
  height: calc(100vh - 245px);
}

.fullscreen.fullscreen-enabled
  .livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller.not-live,
.fullscreen.fullscreen-enabled
  .livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller.not-live
  .discussions-image {
  height: calc(100vh - 180px);
}

.newsChat-detailpage-wrapper .comments-wrapper .comments-scroller,
.newsChat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller
  .discussions-image {
  height: calc(100vh - 250px - 22px);
}

.fullscreen.fullscreen-enabled
  .newsChat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller,
.fullscreen.fullscreen-enabled
  .newsChat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller
  .discussions-image {
  height: calc(100vh - 180px - 22px);
}

.fullscreen.fullscreen-enabled
  .newsChat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller.not-live,
.fullscreen.fullscreen-enabled
  .newsChat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller.not-live
  .discussions-image {
  height: calc(100vh - 180px);
}

.comments-wrapper .comments-scroller .comments-scroller-child {
  display: flex;
  flex-direction: column-reverse;
}
.comments-wrapper .comment-edit {
  font-size: 13px;
  color: #1890ff;
  margin-left: 5px;
}

.comments-wrapper .comment-flag {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 5px;
}
.comments-wrapper .comment-delete {
  font-size: 13px;
  color: #f44336;
  margin-left: 2px;
}
.comments-wrapper .comment-flag.block i.anticon-stop {
  color: #ffc400;
}
.comments-wrapper .comment-flag.block i.anticon-check-circle {
  font-size: 14px;
}
.comments-wrapper .comment-edit,
.comments-wrapper .comment-flag.block,
.comments-wrapper .comment-delete {
  cursor: pointer;
}
.comments-wrapper .loadMore-wrapper {
  text-align: center;
  height: 24px;
}
.comments-wrapper .add-new-comment-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comments-wrapper .add-new-comment-wrapper .ant-btn.ant-btn-primary {
  margin-left: 10px;
}

.comments-wrapper .comment-filter-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.comments-wrapper .comment-filter-wrapper button {
  margin-right: 8px;
}
.comments-wrapper .add-new-comment {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.comments-wrapper .add-new-comment.reply-to .reply-to-wrapper {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: #f2f2f2;
  padding: 7px 5px;
  position: relative;
  display: flex;
  align-items: center;
}
.comments-wrapper .add-new-comment.reply-to .reply-to-wrapper em {
  width: 98%;
}
.comments-wrapper .add-new-comment.reply-to .reply-to-wrapper i {
  position: absolute;
  right: 10px;
  color: #bbbbbb;
  cursor: pointer;
}
.comments-wrapper .add-new-comment.reply-to .reply-to-wrapper {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: #f2f2f2;
  padding: 5px;
}
.comments-wrapper .reply-input-wrapper {
  display: flex;
}
.comments-wrapper .reply.add-new-comment {
  margin-top: 0;
  display: flex;
  flex-direction: row;
}
.comments-wrapper .reply.add-new-comment .ant-btn.ant-btn-primary {
  margin-left: 10px;
}
.comments-wrapper .reply.add-new-comment input {
  height: 30px;
}
.comments-wrapper .add-new-comment input {
  /* margin-right: 10px; */
  height: 50px;
}
.comments-wrapper .ant-comment-content-detail p {
  margin-bottom: 0;
}
.comments-wrapper .ant-comment-actions {
  margin: 0;
}
.comments-wrapper .replied-message .ant-comment-avatar {
  border-left: 2px solid grey;
  padding-left: 10px;
  margin-left: 16px;
}
.comments-wrapper .comment-content {
  border: 1px solid silver;
  border-radius: 5px;
  padding: 8px 15px;
  background: transparent;
  display: flex;
  flex-direction: column;
}
.comments-wrapper .author .comment-content {
  background: #f6f6f6;
  margin-right: 16px;
}
.reply-highlight .ant-comment.author .ant-comment-content-author-name {
  color: #000;
}
.comments-wrapper .ant-comment.reply-highlight {
  background: #f6f6f6;
  border-radius: 3px;
  padding: 5px 2px;
}
.comments-wrapper .blocked.comment-content,
.comments-wrapper .author .blocked.comment-content {
  background: #ffccc7;
}
.comments-wrapper .author .replied-message .comment-content {
  background: transparent;
}
.comments-wrapper .author .replied-message.author .comment-content {
  background: #f6f6f6;
}
.comments-wrapper .author .replied-message.author .blocked.comment-content {
  background: #ffccc7;
}
.comment-message {
  margin-bottom: 5px;
  white-space: pre-wrap;
}
.blocked .comment-message {
  margin-bottom: 0;
}
.blocked .reply-button,
.blocked .like-button {
  pointer-events: none;
}
.comments-wrapper .reply-button,
.comments-wrapper .like-button {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  user-select: none;
}
.comments-wrapper .reply-button:hover,
.comments-wrapper .like-button:hover {
  color: #595959;
}
.comments-wrapper .like-button {
  padding-right: 5px;
}
.comments-wrapper .ant-comment-nested .ant-comment-inner {
  padding: 8px 0;
}
/* .comments-wrapper .ant-comment-nested{
  max-height: 400px;
  overflow: auto;
}
.comments-wrapper .ant-comment-nested::-webkit-scrollbar {
  width: 4px;
}
.comments-wrapper .ant-comment-nested::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.comments-wrapper .ant-comment-nested::-webkit-scrollbar-thumb, .comments-wrapper .ant-comment-nested::-webkit-scrollbar-thumb:hover {
  background: grey;
  border-radius: 250px;
} */
.comments-wrapper .ant-comment-nested .reply-button {
  display: none;
}
.comments-loader-wrapper {
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 1024;
}
.comments-wrapper .ant-collapse-borderless > .ant-collapse-item:last-child,
.comments-wrapper
  .ant-collapse-borderless
  > .ant-collapse-item:last-child
  .ant-collapse-header {
  padding-top: 0;
}
.comments-wrapper
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  top: 32%;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
