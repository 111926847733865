
.ant-layout{
  height: 100vh;
}
.ant-layout-header.header{
  background-color: #fff;
  border-bottom: 1px solid #dee2e6 !important;
}
.content-wrapper{
  /* background: #F2F2F2; */
  background: #f0f3f5;
}
.logo{
  font-size: 20px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  cursor: pointer;
}
.menu-divider-space{
  padding: 0 20px;
}
.menu-divider{
  border-right: 1px dotted silver;
}
.menu-divider.notification{
  text-align: center;
  font-size: 20px;
}
.menu-divider.notification i{
  font-size: 20px;
}
.header .user a{
  color: inherit;
}
.ant-menu-vertical > .ant-menu-item.menu-list,
.ant-menu-vertical-left > .ant-menu-item.menu-list,
.ant-menu-vertical-right > .ant-menu-item.menu-list,
.ant-menu-inline > .ant-menu-item.menu-list{
  height: 50px ;
  line-height: 50px;
  font-size: 17px;
  margin-top: 0px;
}
.ant-menu-vertical > .ant-menu-item.menu-list i,
.ant-menu-vertical-left > .ant-menu-item.menu-list i,
.ant-menu-vertical-right > .ant-menu-item.menu-list i,
.ant-menu-inline > .ant-menu-item.menu-list i{
  font-size: 17px;
}
.anticon.custom-discussions{
  transform: translate3D(0,-1px,0) scale(1.33);
}
.anticon.custom-discussions svg{
  height: 14px;
}
.ant-menu-item-selected .anticon.custom-discussions, .ant-menu-item:hover .anticon.custom-discussions{
  fill: #1890ff
}

.ant-menu-vertical .ant-menu-item.menu-list:not(:last-child), 
.ant-menu-vertical-left .ant-menu-item.menu-list:not(:last-child), 
.ant-menu-vertical-right .ant-menu-item.menu-list:not(:last-child), 
.ant-menu-inline .ant-menu-item.menu-list:not(:last-child) {
  margin-bottom: 4px;
}