.show-detail-page {
  height: 100%;
}

.shows-wrapper {
  padding: 20px;
}

.show-detail-page .show-card {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.show-detail-page .show-card.images-wrapper {
  flex-direction: column;
  justify-content: space-between;
}

.show-card-wrapper {
  max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto;
}

.show-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.show-card img.host-image {
  width: 100px;
}

.show-card img.show-logo {
  object-fit: contain;
}

@media(max-width: 1200px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

@media(max-height: 790px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

.show-card-wrapper .show-title {
  text-align: left;
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}

.shows-list .show-card img {
  transition: all .4s ease;
}

.shows-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

/* add show  */
.add-show-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #DDDDDD;
  border-radius: 5px;
  height: 100%;
  height: 150px;
  max-height: 150px;
  max-width: 300px;
  cursor: pointer;
}

.add-show-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

/* button */
.ant-btn-dashed.add-show-button,
.ant-btn-dashed.add-show-button:hover,
.ant-btn-dashed.add-show-button:focus,
.ant-btn-dashed.add-show-button:active {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.65);
  color: rgba(0, 0, 0, 0.65);
}

.ant-btn-dashed.add-show-button:hover {
  border-style: solid;
}

.add-show-form .ant-progress-outer {
  display: none;
}

@media(min-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+5) {
    margin-top: 50px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+4) {
    margin-top: 50px;
  }
}

@media(max-width: 768px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .show-card.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

/* Detail Page */
.shows-title-wrapper {
  padding: 20px;
  /* padding-top: 40px; */
}

.shows-detail-wrapper {
  padding: 20px 40px;
  padding-top: 40px;
}

.ant-page-header {
  padding: 20px;
}

.show-detail-page .show-description {
  height: 100%;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  /* word-break: break-all; */
}

.show-detail-page .show-description-wrapper {
  margin: 0px 20px;
}

/* .show-detail-page  .show-description-wrapper .ant-row-flex{
  padding: 0px 20px;
} */
.divider-wrapper {
  margin: 0px 20px !important;
}

.show-detail-page .show-author {
  font-size: 15px;
  font-weight: 600;
}

.show-detail-page .show-author span {
  font-size: 15px;
  margin-left: 3px;
  font-weight: 500;
}

.show-detail-page .show-card.images-wrapper {
  overflow: auto;
}

.show-detail-page .show-card.images-wrapper .show-author {
  margin: 10px 0;
}

.episode-loader-wrapper {
  position: fixed;
  bottom: 50px;
  left: 44.7%;
  z-index: 999;
  /* position: absolute;
  left: 50%;
  z-index: 1;
  bottom: 0; */
}

.box.show-all-images {
  height: calc(100vh - 180px);
}

.shows-list.season-filter {
  margin: 10px 24px;
  display: flex;
  justify-content: flex-end;  
  position: relative;
}

.shows-list.season-filter .ant-select {
  width: 250px;
}

.shows-list.season-filter .ant-select:nth-of-type(2) {
  margin-left: 10px;
}

.show-card-wrapper.season-card-details {
  position: relative;
}

.episode-details .season-card-details-wrapper {
  text-align: left;
}

.person-management-wrapper .season-podcastepisode-table-img {
  width: 50px;
  height: 50px;
  object-fit: cover; 
}

.person-management-wrapper .seasonepisode-update-weight-input {
  width: 100px;
}

.person-management-wrapper .season-episode-table-img {
  width: 68px;
  height: 40px;
  object-fit: cover;
}

.person-management-wrapper .seasonepisode-action-buttons-wrapper {
  margin-bottom: 24px;
}

.seasonepisode-action-buttons-wrapper .seasonepisode-action-header {
  justify-content: flex-end;
}

.seasonepisode-action-buttons-wrapper .search-input {
  width:324px
}

.seasonepisode-action-buttons-wrapper .action-btn .cancel-btn {
  margin-right: 10px;
}

.add-seasonepisode-wrapper {
  margin-bottom: 20px;
}

.season-podcast-card-col-wrapper {
  margin-bottom: 24px;
}

.episode-season-wrapper.podcast-episode-season-wrapper {
  padding: 0 10px;
}