@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900);
.podcast-detail-page {
  height: 100%;
}

.podcasts-wrapper {
  padding: 20px;
}

.podcast-detail-page .podcast-card {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.podcast-detail-page .podcast-card.images-wrapper {
  flex-direction: column;
  justify-content: space-between;
}

.podcast-card-wrapper {
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.podcast-card img {
  max-width: 100%;
  width: 100%;
  height: 180px;
  max-height: 180px;
  min-height: 180px;
  object-fit: cover;
}

.podcast-card img.podcast-image {
  width: 150px;
}

.podcast-card img.podcast-logo {
  object-fit: contain;
}

@media(max-width: 1200px) {
  .podcast-detail-page .podcast-card.images-wrapper .podcast-background-image,
  .podcast-detail-page .podcast-card.images-wrapper .podcast-logo-image {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }

  .podcast-detail-page .podcast-card.images-wrapper .podcast-cover-image {
    max-width: 130px;
    max-height: 130px;
    min-height: 50px;
  }
}

@media(max-height: 790px) {
  .podcast-detail-page .podcast-card.images-wrapper .podcast-background-image,
  .podcast-detail-page .podcast-card.images-wrapper .podcast-logo-image {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
  .podcast-detail-page .podcast-card.images-wrapper .podcast-cover-image {
    max-width: 130px;
    max-height: 130px;
    min-height: 50px;
  }
}

.podcast-card-wrapper .podcast-title {
  text-align: left;
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}

.podcasts-list .podcast-card img {
  transition: all .4s ease;
}

.podcasts-list .podcast-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

/* add show  */
.add-podcast-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #DDDDDD;
  height: 100%;
  height: 180px;
  max-height: 180px;
  max-width: 200px;
  cursor: pointer;
}

.add-podcast-wrapper:hover .ant-btn-dashed.add-podcast-button {
  border-style: solid;
}

/* button */
.ant-btn-dashed.add-podcast-button,
.ant-btn-dashed.add-podcast-button:hover,
.ant-btn-dashed.add-podcast-button:focus,
.ant-btn-dashed.add-podcast-button:active {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.65);
  color: rgba(0, 0, 0, 0.65);
}

.ant-btn-dashed.add-podcast-button:hover {
  border-style: solid;
}

.add-podcast-form .ant-progress-outer {
  display: none;
}

@media(min-width: 991px) {
  .podcast-card.ant-col-xl-4:nth-child(n+7) {
    margin-top: 24px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .podcast-card.ant-col-xl-4:nth-child(n+4) {
    margin-top: 24px;
  }
}

@media(max-width: 768px) {
  .add-podcast-wrapper {
    height: 200px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .podcast-card.ant-col-xl-4:nth-child(n+2) {
    margin-top: 24px;
    text-align: center;
  }
  
  .podcast-card-wrapper .podcast-title {
    text-align: center;
  }
}

/* Detail Page */
.podcasts-title-wrapper {
  padding: 20px;
}

.shows-detail-wrapper {
  padding: 20px 40px;
  padding-top: 40px;
}

.ant-page-header {
  padding: 20px;
}

.podcast-detail-page .podcast-description {
  height: 100%;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  word-break: break-all;
}

.podcast-detail-page .podcast-description-wrapper {
  margin: 0px 20px;
}

.divider-wrapper {
  margin: 0px 20px !important;
}

.podcast-detail-page .show-author {
  font-size: 15px;
  font-weight: 600;
}

.podcast-detail-page .show-author span {
  font-size: 15px;
  margin-left: 3px;
  font-weight: 500;
}

.podcast-detail-page .podcast-card.images-wrapper {
  overflow: auto;
}

.podcast-detail-page .podcast-card.images-wrapper .show-author {
  margin: 10px 0;
}

.episode-loader-wrapper {
  position: fixed;
  bottom: 50px;
  left: 44.7%;
  z-index: 999;
}

.podcast-loader-card .podcast-loader-card-wrapper {
  max-width: 100%;
  width: 200px;
  height: 180px;
  max-height: 180px;
  margin: 0 auto;
}

.ant-page-header.podcast-pageheader-wordbreak {
  display: flex;
}

.ant-page-header.podcast-pageheader-wordbreak .ant-page-header-heading {
  width: calc(100% - 220px);
}

.ant-page-header.podcast-pageheader-wordbreak .ant-page-header-heading-title {
  word-break: break-all;
  display: inline;
}
.box-image {
  padding: 0;
}

.box-insider.box-image {
  padding: 1rem;
}

.mask {
  background-color: rgba(0, 0, 0, .7);
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.episode-wrapper {
  padding: 0 20px;
}

.live-segment-wrapper {
  padding-bottom: 20px;
}

.episode-title-wrapper {
  padding: 20px;
}

.episode-page-header {
  padding-bottom: 0;
}

.episode-number-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.episode-number-wrapper p {
  margin-bottom: 0;
}

.episode-number-wrapper input.ant-input {
  height: 24px;
}

.episode-wrapper .description {
  background: transparent;
  margin: 1em;
}

.episode-wrapper .description-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.3em;
}

/* .episode-list{
  max-height: calc(100vh - 141px - 105px - 64px - 50px);
  overflow: auto;
  padding: 20px;
	padding-top: 0;
}
@media(max-width: 1200px){
  .episode-list{
    max-height: calc(100vh - 141px - 105px - 64px - 80px);
  }
} */
.episode-list-wrapper {
  max-height: calc(100vh - 97px - 64px);
  overflow: auto;
}

.add-episode-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DDDDDD;
  border-radius: 5px;
  max-height: 150px;
  min-height: 120px;
  height: 150px;
  cursor: pointer;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.add-episode-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

@media(max-width: 767px) {
  .add-episode-wrapper {
    margin-bottom: 2rem;
  }
}

.episode-card-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.episode-card-wrapper .ant-tag {
  position: absolute;
  right: 0;
  top: 10px;
}

@media(min-width: 991px) {
  .episode-card-col-wrapper.ant-col-xl-6:nth-child(n+5) {
    margin-top: 40px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .episode-card-col-wrapper.ant-col-xl-6:nth-child(n+4) {
    margin-top: 40px;
  }
}

@media(max-width: 767px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .episode-card-col-wrapper.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

.episode-card img {
  max-width: 100%;
  border-radius: 5px;
  /* width: 300px; */
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.episode-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

.episode-details {
  margin-left: 0.3em;
}

.episode-title {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0;
}

.episode-date {
  color: rgb(39, 40, 50);
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.episode-description-div-wrapper {
  margin: 0px 20px;
}

.episode-description-div-wrapper .episode-card {
  display: flex;
  align-items: center;
  height: 100%;
}

.episode-description-wrapper {
  padding: 0px 20px;
}

.episode-description-wrapper .episode-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  object-fit: cover;
}

.episode-description-wrapper .episode-description {
  /* height: 100%; */
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  /* word-break: break-all; */
}

.episode-description-wrapper .featured-image-link {
  text-decoration: underline;
  cursor: pointer;
}

.episode-wrapper .title-wrapper h4 {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px;
}

.episode-wrapper .segmenet-title {
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .box-image .segmenet-title {
  color: white;
}

.episode-wrapper .upload-image-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .upload-image-wrapper button {
  background: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid;
  padding: 0;
  border-radius: 0;
}

.dropzone-wrapper {
  margin-bottom: 2em;
}

.dropzone-wrapper .ant-col {
  padding-left: 24px;
  padding-right: 24px;
}

.episode-wrapper .box-image .video-uploader .ant-progress-text,
.episode-wrapper .box-image .audio-uploader .ant-progress-text {
  color: white;
}

.episode-wrapper .video-uploader .ant-progress-text,
.episode-wrapper .audio-uploader .ant-progress-text {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card {
  background: transparent;
  width: 80%;
  margin: 0 auto;
  height: 180px;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .upload-image-wrapper button {
  color: white;
  font-weight: 600;
}

.episode-wrapper .play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  min-height: 180px;
  border: 1px dashed;
  border-width: 1.8px;
  border-color: #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.episode-wrapper .play-button span {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .box-image .play-button span,
.episode-wrapper .box-image .play-button button span,
.episode-wrapper .play-button button span {
  color: #fff;
}

.episode-wrapper .box-image .play-button {
  border-color: white;
}

.episode-wrapper .play-button .remove {
  position: absolute;
  top: -18px;
  right: -14px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .play-button.download-play-wrapper {
  flex-direction: column;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card:hover,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card:hover {
  border-width: 1.8px;
  border-color: white;
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card i,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card i {
  font-size: 38px;
  margin-bottom: 0.3em;
}

.episode-wrapper .parts-lists h4 {
  margin-bottom: 0;
}

.episode-wrapper .parts-lists .ant-list-item-meta-description {
  font-size: 12px;
}

.episode-wrapper .parts-lists i {
  font-size: 20px;
}

.episode-wrapper .parts-lists .ant-list-item {
  cursor: pointer;
  padding: 0.7em 1em;
  border-radius: 2px;
  min-height: 50px;
}

.episode-wrapper .parts-lists .ant-list-item.active {
  background: #e8e8e8;
}

.episode-wrapper .parts-lists .ant-list-item.uploading,
.add-segment-button.uploading {
  pointer-events: none;
}

.segment-live-table .ant-table-title {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant: tabular-nums;
  line-height: 1.5;
}

.segment-live-table i {
  cursor: pointer;
  font-size: 16px;
  transition: all .4s ease;
  color: #1890ff;
  margin-left: 5px;
}

.segment-live-table i:hover {
  -webkit-transform: translate3D(0, -1px, 0) scale(1.3);
          transform: translate3D(0, -1px, 0) scale(1.3);
  transition: all .4s ease;
}

.episode-wrapper .episode-live-details div {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.episode-wrapper .episode-live-details div span {
  font-weight: normal;
  margin-left: 0.3rem;
  word-break: break-all;
  /* color: #2094ff; */
}

.add-episode-form .ant-progress-outer {
  display: none;
}

.add-episode-form .allowed-continents {
  width: 100%;
}

.segment-more-button:hover {
  visibility: visible !important;
}

.episode-wrapper .ant-table-row:nth-child(even),
.episode-wrapper .ant-table-row:nth-child(odd),
.episode-wrapper .ant-table-thead>tr>th {
  background: transparent !important;
}

.episode-wrapper td:last-child {
  word-break: break-all;
}

.episode-wrapper .player-section-wrapper .box {
  height: 100%;
}

.episode-wrapper .player-section-wrapper,
.episode-wrapper .live-details-section-wrapper {
  padding-bottom: 20px;
}

@media(max-width: 991px) {
  .episode-wrapper .player-section-wrapper {
    width: 100%;
    margin-top: 30px;
  }

  .segment-details-wrapper {
    margin-top: 30px;
  }
}

.segment-details-wrapper .box,
.segment-lists-wrapper .box {
  height: 100%;
}

.episode-wrapper .ant-upload-list.ant-upload-list-picture {
  display: none;
}

.segment-live-image-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.segment-description {
  word-break: break-all;
  margin-top: 10px;
}

.add-episode-form .ant-form-item.simulate-live-chk {
  margin-left: 1rem;
}

#upload-live-video-aws,
#upload-live-video-aws i { 
  margin-left: 0;
}

#upload-live-video-aws .play-button-margin {
  margin-right: 10px;
}

/* .live-player .video-react-time-control, .live-player .video-react-progress-control, .live-player .video-react .video-react-progress-control {
  display: none !important;
} */

.add-subtitles-form .d-none.ant-form-item {
  display: none;
}

.add-subtitles-form .remove-subtitle-icon {
  margin-top: 1.8rem;
}

.add-subtitles-form .submit-subtitle-btn {
  margin-right: 20%;
}

.episode-subtitle-divider{
  margin: 0 4px;
}

label.ant-radio-button-wrapper {
  margin-right: 10px;
}

.box-image {
  padding: 0;
}

.box-insider.box-image {
  padding: 1rem;
}

.mask {
  background-color: rgba(0, 0, 0, .7);
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.episode-wrapper {
  padding: 0 20px;
}

.live-segment-wrapper {
  padding-bottom: 20px;
}

.episode-title-wrapper {
  padding: 20px;
}

.episode-page-header {
  padding-bottom: 0;
}

.episode-number-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.episode-number-wrapper p {
  margin-bottom: 0;
}

.episode-number-wrapper input.ant-input {
  height: 24px;
}

.episode-wrapper .description {
  background: transparent;
  margin: 1em;
}

.episode-wrapper .description-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.3em;
}

.add-podcastepisode-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DDDDDD;
  max-height: 200px;
  min-height: 120px;
  height: 200px;
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}

.add-episode-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

@media(max-width: 767px) {
  .add-episode-wrapper {
    margin-bottom: 2rem;
  }
}

.episode-card-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.episode-card-wrapper .ant-tag {
  position: absolute;
  right: 0;
  top: 10px;
}

@media(min-width: 991px) {
  .podcastepisode-card-col-wrapper.ant-col-xl-6:nth-child(n+5) {
    margin-top: 24px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .podcastepisode-card-col-wrapper.ant-col-xl-6:nth-child(n+4) {
    margin-top: 24px;
  }
}

@media(max-width: 767px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .podcastepisode-card-col-wrapper.ant-col-xl-6:nth-child(n+2) {
    margin-top: 24px;
    text-align: center;
  }
}

.episode-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.episode-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

.episode-details {
  margin-left: 0.3em;
}

.episode-title {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0;
}

.episode-date {
  color: rgb(39, 40, 50);
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.episode-description-div-wrapper {
  margin: 0px 20px;
}

.episode-description-div-wrapper .episode-card {
  display: flex;
  align-items: center;
  height: 100%;
}

.podcastepisode-description-wrapper {
  padding: 0px 20px;
}

.podcastepisode-description-wrapper .episode-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  object-fit: cover;
}

.podcastepisode-description-wrapper .episode-description {
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  padding-left: 20px;
}

@media(max-width: 767px) {
  .podcastepisode-description-wrapper .episode-description {
    padding-left:  0;
  }
}

.podcastepisode-description-wrapper .featured-image-link {
  text-decoration: underline;
  cursor: pointer;
}

.episode-wrapper .title-wrapper h4 {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px;
}

.episode-wrapper .segmenet-title {
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .box-image .segmenet-title {
  color: white;
}

.episode-wrapper .upload-image-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .upload-image-wrapper button {
  background: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid;
  padding: 0;
  border-radius: 0;
}

.dropzone-wrapper {
  margin-bottom: 2em;
}

.dropzone-wrapper .ant-col {
  padding-left: 24px;
  padding-right: 24px;
}

.episode-wrapper .box-image .video-uploader .ant-progress-text,
.episode-wrapper .box-image .audio-uploader .ant-progress-text {
  color: white;
}

.episode-wrapper .video-uploader .ant-progress-text,
.episode-wrapper .audio-uploader .ant-progress-text {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card {
  background: transparent;
  width: 80%;
  margin: 0 auto;
  height: 180px;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .upload-image-wrapper button {
  color: white;
  font-weight: 600;
}

.episode-wrapper .play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  min-height: 180px;
  border: 1px dashed;
  border-width: 1.8px;
  border-color: #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.episode-wrapper .play-button span {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .box-image .play-button span,
.episode-wrapper .box-image .play-button button span,
.episode-wrapper .play-button button span {
  color: #fff;
}

.episode-wrapper .box-image .play-button {
  border-color: white;
}

.episode-wrapper .play-button .remove {
  position: absolute;
  top: -18px;
  right: -14px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .play-button.download-play-wrapper {
  flex-direction: column;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card:hover,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card:hover {
  border-width: 1.8px;
  border-color: white;
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card i,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card i {
  font-size: 38px;
  margin-bottom: 0.3em;
}

.episode-wrapper .parts-lists h4 {
  margin-bottom: 0;
}

.episode-wrapper .parts-lists .ant-list-item-meta-description {
  font-size: 12px;
}

.episode-wrapper .parts-lists i {
  font-size: 20px;
}

.episode-wrapper .parts-lists .ant-list-item {
  cursor: pointer;
  padding: 0.7em 1em;
  border-radius: 2px;
  min-height: 50px;
}

.episode-wrapper .parts-lists .ant-list-item.active {
  background: #e8e8e8;
}

.episode-wrapper .parts-lists .ant-list-item.uploading,
.add-segment-button.uploading {
  pointer-events: none;
}

.segment-live-table .ant-table-title {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant: tabular-nums;
  line-height: 1.5;
}

.segment-live-table i {
  cursor: pointer;
  font-size: 16px;
  transition: all .4s ease;
  color: #1890ff;
  margin-left: 5px;
}

.segment-live-table i:hover {
  -webkit-transform: translate3D(0, -1px, 0) scale(1.3);
          transform: translate3D(0, -1px, 0) scale(1.3);
  transition: all .4s ease;
}

.episode-wrapper .episode-live-details div {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.episode-wrapper .episode-live-details div span {
  font-weight: normal;
  margin-left: 0.3rem;
  word-break: break-all;
}

.add-episode-form .ant-progress-outer {
  display: none;
}

.add-episode-form .allowed-continents {
  width: 100%;
}

.segment-more-button:hover {
  visibility: visible !important;
}

.episode-wrapper .ant-table-row:nth-child(even),
.episode-wrapper .ant-table-row:nth-child(odd),
.episode-wrapper .ant-table-thead>tr>th {
  background: transparent !important;
}

.episode-wrapper td:last-child {
  word-break: break-all;
}

.episode-wrapper .player-section-wrapper .box {
  height: 100%;
}

.episode-wrapper .player-section-wrapper,
.episode-wrapper .live-details-section-wrapper {
  padding-bottom: 20px;
}

@media(max-width: 991px) {
  .episode-wrapper .player-section-wrapper {
    width: 100%;
    margin-top: 30px;
  }

  .segment-details-wrapper {
    margin-top: 30px;
  }
}

.segment-details-wrapper .box,
.segment-lists-wrapper .box {
  height: 100%;
}

.episode-wrapper .ant-upload-list.ant-upload-list-picture {
  display: none;
}

.segment-live-image-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.segment-description {
  word-break: break-all;
  margin-top: 10px;
}

.podcast-tabs {
  width: 100%;
}

.podacast-card-episode-wrapper {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}

.podacast-card-episode-wrapper img{
  max-width: 100%;
  width: 100%;
  max-height: 200px;
  min-height: 200px;
  object-fit: cover;
}

.podacast-card-episode-wrapper .ant-tag {
  position: absolute;
  right: 0;
  top: 10px;
}

.podcast-episode.episode-card img{
  max-width: 100%;
  width: 200px;
  max-height: 200px;
  min-height: 200px;
  object-fit: cover;
  border-radius: 0;
}

.add-episode-form .podcastepisode-publishdate{
  min-width: 220px;
}

.episode-list .no-seasons-found-wrapper {
  text-align: center;
  width: 100%;
}

.podcastepisode-description-divider {
  margin: 0 20px !important;
}

.dropzone-wrapper .audio-upload-wrapper {
  padding: 0 24px !important;
}

.play-button.download-play-wrapper #btn-audiowithoutads-play,
.play-button.download-play-wrapper #btn-audiowithads-play {
  margin-right: 10px !important;
}

.episode-list.podcast-detail-page  .episode-card-wrapper{
  width: 100%;
}

.play-button.play-button.download-play-wrapper .audio-label {
  margin-top: 10px;
}

.audio-required  .ant-upload-select {
  border-color: #f5222d !important;
}

.audio-required-warning  {
  color: #f5222d !important;
  margin-top: 10px;
}

.add-podcastepisode-form .d-none.ant-form-item {
  display: none !important;
}

.podcast-audio-player audio[aria-label='download media'] {
  display: none !important;
}

.podcast-audio-player audio::-webkit-media-controls-enclosure {
  overflow: hidden !important;
}

.podcast-audio-player audio::-webkit-media-controls-panel {
  width: calc(100% + 30px) !important; /* Adjust as needed */
}
.show-detail-page {
  height: 100%;
}

.shows-wrapper {
  padding: 20px;
}

.show-detail-page .show-card {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.show-detail-page .show-card.images-wrapper {
  flex-direction: column;
  justify-content: space-between;
}

.show-card-wrapper {
  max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto;
}

.show-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.show-card img.host-image {
  width: 100px;
}

.show-card img.show-logo {
  object-fit: contain;
}

@media(max-width: 1200px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

@media(max-height: 790px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

.show-card-wrapper .show-title {
  text-align: left;
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}

.shows-list .show-card img {
  transition: all .4s ease;
}

.shows-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

/* add show  */
.add-show-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #DDDDDD;
  border-radius: 5px;
  height: 100%;
  height: 150px;
  max-height: 150px;
  max-width: 300px;
  cursor: pointer;
}

.add-show-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

/* button */
.ant-btn-dashed.add-show-button,
.ant-btn-dashed.add-show-button:hover,
.ant-btn-dashed.add-show-button:focus,
.ant-btn-dashed.add-show-button:active {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.65);
  color: rgba(0, 0, 0, 0.65);
}

.ant-btn-dashed.add-show-button:hover {
  border-style: solid;
}

.add-show-form .ant-progress-outer {
  display: none;
}

@media(min-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+5) {
    margin-top: 50px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+4) {
    margin-top: 50px;
  }
}

@media(max-width: 768px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .show-card.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

/* Detail Page */
.shows-title-wrapper {
  padding: 20px;
  /* padding-top: 40px; */
}

.shows-detail-wrapper {
  padding: 20px 40px;
  padding-top: 40px;
}

.ant-page-header {
  padding: 20px;
}

.show-detail-page .show-description {
  height: 100%;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  word-break: break-all;
}

.show-detail-page .show-description-wrapper {
  margin: 0px 20px;
}

/* .show-detail-page  .show-description-wrapper .ant-row-flex{
  padding: 0px 20px;
} */
.divider-wrapper {
  margin: 0px 20px !important;
}

.show-detail-page .show-author {
  font-size: 15px;
  font-weight: 600;
}

.show-detail-page .show-author span {
  font-size: 15px;
  margin-left: 3px;
  font-weight: 500;
}

.show-detail-page .show-card.images-wrapper {
  overflow: auto;
}

.show-detail-page .show-card.images-wrapper .show-author {
  margin: 10px 0;
}

.episode-loader-wrapper {
  position: fixed;
  bottom: 50px;
  left: 44.7%;
  z-index: 999;
  /* position: absolute;
  left: 50%;
  z-index: 1;
  bottom: 0; */
}

.box.show-all-images {
  height: calc(100vh - 180px);
}
.comments-wrapper {
  padding: 20px;
  border-top: 1px solid silver;
}
.episode-wrapper .comments-wrapper {
  border: 0;
}
.live-segment-wrapper .comments-wrapper {
  padding: 20px 0;
}
.comments-wrapper .comments-scroller {
  position: relative;
  /* height: calc(100vh - 233px - 42px - 88px - 130px); */
  height: calc(100vh - 233px - 42px - 65px - 22px);
  overflow: auto;
  margin-bottom: 10px;
  /* scroll-behavior: smooth; */
  z-index: 1;
}
.fullscreen.fullscreen-enabled .comments-wrapper .comments-scroller {
  height: calc(100vh - 233px - 42px - 22px);
}
.comments-wrapper .comments-scroller.enable-reply {
  height: calc(100vh - 233px - 42px - 65px - 31px - 22px);
}
.comments-wrapper .comments-scroller .discussions-image {
  height: calc(100vh - 233px - 42px - 65px - 22px);
}
.comments-wrapper .comments-scroller.not-live,
.comments-wrapper .comments-scroller.not-live .discussions-image {
  height: calc(100vh - 233px - 42px - 36px - 130px);
}
.comments-wrapper .comments-scroller.not-live.no-description,
.comments-wrapper
  .comments-scroller.not-live.no-description
  .discussions-image {
  height: calc(100vh - 233px - 42px - 16px);
}
.fullscreen.fullscreen-enabled .comments-wrapper .comments-scroller.not-live,
.fullscreen.fullscreen-enabled
  .comments-wrapper
  .comments-scroller.not-live
  .discussions-image {
  height: calc(100vh - 233px - 42px - 90px);
}
.fullscreen.fullscreen-enabled
  .comments-wrapper
  .comments-scroller.not-live.no-description,
.fullscreen.fullscreen-enabled
  .comments-wrapper
  .comments-scroller.not-live.no-description
  .discussions-image {
  height: calc(100vh - 180px);
}
.livechat-detailpage-wrapper .comments-wrapper .comments-scroller,
.livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller
  .discussions-image {
  height: calc(100vh - 245px - 50px - 22px);
}

.fullscreen.fullscreen-enabled
  .livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller,
.fullscreen.fullscreen-enabled
  .livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller
  .discussions-image {
  height: calc(100vh - 245px - 22px);
}
.livechat-detailpage-wrapper .comments-wrapper .comments-scroller.not-live,
.livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller.not-live
  .discussions-image {
  height: calc(100vh - 245px);
}

.fullscreen.fullscreen-enabled
  .livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller.not-live,
.fullscreen.fullscreen-enabled
  .livechat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller.not-live
  .discussions-image {
  height: calc(100vh - 180px);
}

.newsChat-detailpage-wrapper .comments-wrapper .comments-scroller,
.newsChat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller
  .discussions-image {
  height: calc(100vh - 250px - 22px);
}

.fullscreen.fullscreen-enabled
  .newsChat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller,
.fullscreen.fullscreen-enabled
  .newsChat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller
  .discussions-image {
  height: calc(100vh - 180px - 22px);
}

.fullscreen.fullscreen-enabled
  .newsChat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller.not-live,
.fullscreen.fullscreen-enabled
  .newsChat-detailpage-wrapper
  .comments-wrapper
  .comments-scroller.not-live
  .discussions-image {
  height: calc(100vh - 180px);
}

.comments-wrapper .comments-scroller .comments-scroller-child {
  display: flex;
  flex-direction: column-reverse;
}
.comments-wrapper .comment-edit {
  font-size: 13px;
  color: #1890ff;
  margin-left: 5px;
}

.comments-wrapper .comment-flag {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 5px;
}
.comments-wrapper .comment-delete {
  font-size: 13px;
  color: #f44336;
  margin-left: 2px;
}
.comments-wrapper .comment-flag.block i.anticon-stop {
  color: #ffc400;
}
.comments-wrapper .comment-flag.block i.anticon-check-circle {
  font-size: 14px;
}
.comments-wrapper .comment-edit,
.comments-wrapper .comment-flag.block,
.comments-wrapper .comment-delete {
  cursor: pointer;
}
.comments-wrapper .loadMore-wrapper {
  text-align: center;
  height: 24px;
}
.comments-wrapper .add-new-comment-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comments-wrapper .add-new-comment-wrapper .ant-btn.ant-btn-primary {
  margin-left: 10px;
}

.comments-wrapper .comment-filter-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.comments-wrapper .comment-filter-wrapper button {
  margin-right: 8px;
}
.comments-wrapper .add-new-comment {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.comments-wrapper .add-new-comment.reply-to .reply-to-wrapper {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: #f2f2f2;
  padding: 7px 5px;
  position: relative;
  display: flex;
  align-items: center;
}
.comments-wrapper .add-new-comment.reply-to .reply-to-wrapper em {
  width: 98%;
}
.comments-wrapper .add-new-comment.reply-to .reply-to-wrapper i {
  position: absolute;
  right: 10px;
  color: #bbbbbb;
  cursor: pointer;
}
.comments-wrapper .add-new-comment.reply-to .reply-to-wrapper {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: #f2f2f2;
  padding: 5px;
}
.comments-wrapper .reply-input-wrapper {
  display: flex;
}
.comments-wrapper .reply.add-new-comment {
  margin-top: 0;
  display: flex;
  flex-direction: row;
}
.comments-wrapper .reply.add-new-comment .ant-btn.ant-btn-primary {
  margin-left: 10px;
}
.comments-wrapper .reply.add-new-comment input {
  height: 30px;
}
.comments-wrapper .add-new-comment input {
  /* margin-right: 10px; */
  height: 50px;
}
.comments-wrapper .ant-comment-content-detail p {
  margin-bottom: 0;
}
.comments-wrapper .ant-comment-actions {
  margin: 0;
}
.comments-wrapper .replied-message .ant-comment-avatar {
  border-left: 2px solid grey;
  padding-left: 10px;
  margin-left: 16px;
}
.comments-wrapper .comment-content {
  border: 1px solid silver;
  border-radius: 5px;
  padding: 8px 15px;
  background: transparent;
  display: flex;
  flex-direction: column;
}
.comments-wrapper .author .comment-content {
  background: #f6f6f6;
  margin-right: 16px;
}
.reply-highlight .ant-comment.author .ant-comment-content-author-name {
  color: #000;
}
.comments-wrapper .ant-comment.reply-highlight {
  background: #f6f6f6;
  border-radius: 3px;
  padding: 5px 2px;
}
.comments-wrapper .blocked.comment-content,
.comments-wrapper .author .blocked.comment-content {
  background: #ffccc7;
}
.comments-wrapper .author .replied-message .comment-content {
  background: transparent;
}
.comments-wrapper .author .replied-message.author .comment-content {
  background: #f6f6f6;
}
.comments-wrapper .author .replied-message.author .blocked.comment-content {
  background: #ffccc7;
}
.comment-message {
  margin-bottom: 5px;
  white-space: pre-wrap;
}
.blocked .comment-message {
  margin-bottom: 0;
}
.blocked .reply-button,
.blocked .like-button {
  pointer-events: none;
}
.comments-wrapper .reply-button,
.comments-wrapper .like-button {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.comments-wrapper .reply-button:hover,
.comments-wrapper .like-button:hover {
  color: #595959;
}
.comments-wrapper .like-button {
  padding-right: 5px;
}
.comments-wrapper .ant-comment-nested .ant-comment-inner {
  padding: 8px 0;
}
/* .comments-wrapper .ant-comment-nested{
  max-height: 400px;
  overflow: auto;
}
.comments-wrapper .ant-comment-nested::-webkit-scrollbar {
  width: 4px;
}
.comments-wrapper .ant-comment-nested::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.comments-wrapper .ant-comment-nested::-webkit-scrollbar-thumb, .comments-wrapper .ant-comment-nested::-webkit-scrollbar-thumb:hover {
  background: grey;
  border-radius: 250px;
} */
.comments-wrapper .ant-comment-nested .reply-button {
  display: none;
}
.comments-loader-wrapper {
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 1024;
}
.comments-wrapper .ant-collapse-borderless > .ant-collapse-item:last-child,
.comments-wrapper
  .ant-collapse-borderless
  > .ant-collapse-item:last-child
  .ant-collapse-header {
  padding-top: 0;
}
.comments-wrapper
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  top: 32%;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.discussions-wrapper{
  padding: 20px;
}
.discussions-detailpage-wrapper{
  height: 100%;
}
.title-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.title-wrapper h2.ant-typography{
  margin-bottom: 0;
}
.discussions-subject{
  color: rgba(0, 0, 0, 0.65);
}
.discussions-detailpage-wrapper .discussions-description{
  margin: 20px 0;
  margin-bottom: 0;
  max-height: 130px;
}
.discussions-detailpage-wrapper .discussions-description .description-content{
  height: 80px;
  max-height: 80px;
  overflow: auto;
}
.discussions-image{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.discussions-image p{
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}
.discussions-wrapper .ant-calendar-picker-input.ant-input{
  padding: 4px;
  font-size: 13px;
}
.discussions-wrapper .ant-table-row td:first-child, .discussions-wrapper .ant-table-row td:first-child:hover a {
  cursor: pointer;
  color: #40a9ff;
}
.discussions-wrapper .ant-pagination{
  text-align: right;
  margin-top: 20px;
}

.discussions-wrapper .page-change-btn-wrapper {
  float: right;
  margin-top: 10px;
}

.discussions-wrapper .link-button {
  padding: 0;
}
.discussions-wrapper .link-button-icon {
  font-size: 14px; 
  margin-left: 8px;
}
.discussions-wrapper .check-cirlce-icon {
  font-size: 18px;  
}
.discussions-wrapper .close-button-icon {
  font-size: 18px;   
  color: #F44336;
}
/* .fullscreen.fullscreen-enabled .discussions-wrapper .comments-filter-dropdown{
  display: none;
} */

.live-chat-wrapper{
  padding: 20px;
}
.title-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.title-wrapper h2.ant-typography{
  margin-bottom: 0;
}

.livechat-detailpage-wrapper{
  height: 100%;
}
.title-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.title-wrapper h2.ant-typography{
  margin-bottom: 0;
}

.livechat-subject{
  color: rgba(0, 0, 0, 0.65);
}
.livechat-detailpage-wrapper .livechat-description{
  margin: 20px 0;
  margin-bottom: 0;
}
.livechat-image{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.livechat-image p{
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}
.livechat-wrapper .ant-calendar-picker-input.ant-input{
  padding: 4px;
  font-size: 13px;
}
/* .live-chat-wrapper .ant-table-row td:first-child,
.live-chat-wrapper .ant-table-row td:first-child a{

} */
.live-chat-wrapper .ant-table-row td:first-child:hover, .live-chat-wrapper .ant-table-row td:first-child:hover a {
  cursor: pointer !important;
  color: #40a9ff;
}
.live-chat-wrapper .ant-pagination{
  text-align: right;
  margin-top: 20px;
}
.live-chat-wrapper .page-change-btn-wrapper {
  float: right;
  margin-top: 10px;
}
.live-chat-wrapper .link-button {
  padding: 0;
}
.live-chat-wrapper .link-button-icon {
  font-size: 14px; 
  margin-left: 8px;
}
.user-management-wrapper {
  padding: 20px;
  height: 100%;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.title-wrapper h2.ant-typography {
  margin-bottom: 0;
}

.user-management-wrapper .profileImage-wrapper {
  text-align: center;
}

.user-management-wrapper .profileImage-wrapper img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.user-management-wrapper .ant-upload-select-picture {
  border-radius: 50%;
}

.user-management-wrapper .ant-upload-list-item-uploading {
  display: none;
}

.user-management-wrapper .ant-form-item-control {
  line-height: 30px;
}

.user-management-wrapper .ant-pagination {
  text-align: right;
  margin-top: 20px;
}
.is-inactive {
  background-color: #ffefef !important
}
.is-inactive:hover {
  background-color: #ffefef !important
}

.email-link-button {
  padding: 0!important;
}

.user-management-wrapper .page-change-btn-wrapper {
  float: right;
}
.person-management-wrapper {
  padding: 20px;
  height: 100%;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.title-wrapper h2.ant-typography {
  margin-bottom: 0;
}

.person-management-wrapper .profileImage-wrapper {
  text-align: center;
}

.person-management-wrapper .profileImage-wrapper img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.person-management-wrapper .ant-upload-select-picture {
  border-radius: 50%;
}

.person-management-wrapper .ant-upload-list-item-uploading {
  display: none;
}

.person-management-wrapper .ant-form-item-control {
  line-height: 30px;
}

/* .action-icons .anticon.anticon-edit, .action-icons .anticon.anticon-stop, .action-icons .anticon.anticon-sync{
} */
.action-icons .anticon.anticon-stop,
.action-icons .anticon.anticon-sync,
.action-icons .anticon.anticon-key,
.action-icons .anticon.anticon-check-circle {
  margin-left: 6px;
}

.action-icons .anticon.anticon-stop {
  font-size: 14px;
  color: #F44336;
}

.action-icons .anticon.anticon-check-circle {
  font-size: 15px;
}

.action-icons .anticon.anticon-sync {
  font-size: 14px;
  color: #1890ff;
}

.person-management-wrapper .ant-pagination {
  text-align: right;
  margin-top: 20px;
}

.is-inactive {
  background-color: #ffefef !important
}
.is-inactive:hover {
  background-color: #ffefef !important
}

.email-link-button {
  padding: 0!important;
}

.person-management-wrapper .page-change-btn-wrapper {
  float: right;
}

.ant-layout{
  height: 100vh;
}
.ant-layout-header.header{
  background-color: #fff;
  border-bottom: 1px solid #dee2e6 !important;
}
.content-wrapper{
  /* background: #F2F2F2; */
  background: #f0f3f5;
}
.logo{
  font-size: 20px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  cursor: pointer;
}
.menu-divider-space{
  padding: 0 20px;
}
.menu-divider{
  border-right: 1px dotted silver;
}
.menu-divider.notification{
  text-align: center;
  font-size: 20px;
}
.menu-divider.notification i{
  font-size: 20px;
}
.header .user a{
  color: inherit;
}
.ant-menu-vertical > .ant-menu-item.menu-list,
.ant-menu-vertical-left > .ant-menu-item.menu-list,
.ant-menu-vertical-right > .ant-menu-item.menu-list,
.ant-menu-inline > .ant-menu-item.menu-list{
  height: 50px ;
  line-height: 50px;
  font-size: 17px;
  margin-top: 0px;
}
.ant-menu-vertical > .ant-menu-item.menu-list i,
.ant-menu-vertical-left > .ant-menu-item.menu-list i,
.ant-menu-vertical-right > .ant-menu-item.menu-list i,
.ant-menu-inline > .ant-menu-item.menu-list i{
  font-size: 17px;
}
.anticon.custom-discussions{
  -webkit-transform: translate3D(0,-1px,0) scale(1.33);
          transform: translate3D(0,-1px,0) scale(1.33);
}
.anticon.custom-discussions svg{
  height: 14px;
}
.ant-menu-item-selected .anticon.custom-discussions, .ant-menu-item:hover .anticon.custom-discussions{
  fill: #1890ff
}

.ant-menu-vertical .ant-menu-item.menu-list:not(:last-child), 
.ant-menu-vertical-left .ant-menu-item.menu-list:not(:last-child), 
.ant-menu-vertical-right .ant-menu-item.menu-list:not(:last-child), 
.ant-menu-inline .ant-menu-item.menu-list:not(:last-child) {
  margin-bottom: 4px;
}
.badge-description {
  word-break: break-all;
  max-width: 300px;
}

.badgemgnt-table-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover; 
}

.badgemgnt-edit-btn {
  font-size: 14px !important;
  margin-left: 8px !important;
}

.page-change-btn-wrapper{
  float: right;
  margin-top: 10px;
}
.show-detail-page {
  height: 100%;
}

.shows-wrapper {
  padding: 20px;
}

.show-detail-page .show-card {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.show-detail-page .show-card.images-wrapper {
  flex-direction: column;
  justify-content: space-between;
}

.show-card-wrapper {
  max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto;
}

.show-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.show-card img.host-image {
  width: 100px;
}

.show-card img.show-logo {
  object-fit: contain;
}

@media(max-width: 1200px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

@media(max-height: 790px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

.show-card-wrapper .show-title {
  text-align: left;
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}

.shows-list .show-card img {
  transition: all .4s ease;
}

.shows-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

/* add show  */
.add-show-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #DDDDDD;
  border-radius: 5px;
  height: 100%;
  height: 150px;
  max-height: 150px;
  max-width: 300px;
  cursor: pointer;
}

.add-show-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

/* button */
.ant-btn-dashed.add-show-button,
.ant-btn-dashed.add-show-button:hover,
.ant-btn-dashed.add-show-button:focus,
.ant-btn-dashed.add-show-button:active {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.65);
  color: rgba(0, 0, 0, 0.65);
}

.ant-btn-dashed.add-show-button:hover {
  border-style: solid;
}

.add-show-form .ant-progress-outer {
  display: none;
}

@media(min-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+5) {
    margin-top: 50px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+4) {
    margin-top: 50px;
  }
}

@media(max-width: 768px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .show-card.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

/* Detail Page */
.shows-title-wrapper {
  padding: 20px;
  /* padding-top: 40px; */
}

.shows-detail-wrapper {
  padding: 20px 40px;
  padding-top: 40px;
}

.ant-page-header {
  padding: 20px;
}

.show-detail-page .show-description {
  height: 100%;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  /* word-break: break-all; */
}

.show-detail-page .show-description-wrapper {
  margin: 0px 20px;
}

/* .show-detail-page  .show-description-wrapper .ant-row-flex{
  padding: 0px 20px;
} */
.divider-wrapper {
  margin: 0px 20px !important;
}

.show-detail-page .show-author {
  font-size: 15px;
  font-weight: 600;
}

.show-detail-page .show-author span {
  font-size: 15px;
  margin-left: 3px;
  font-weight: 500;
}

.show-detail-page .show-card.images-wrapper {
  overflow: auto;
}

.show-detail-page .show-card.images-wrapper .show-author {
  margin: 10px 0;
}

.episode-loader-wrapper {
  position: fixed;
  bottom: 50px;
  left: 44.7%;
  z-index: 999;
  /* position: absolute;
  left: 50%;
  z-index: 1;
  bottom: 0; */
}

.box.show-all-images {
  height: calc(100vh - 180px);
}
.show-detail-page {
  height: 100%;
}

.shows-wrapper {
  padding: 20px;
}

.show-detail-page .show-card {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.show-detail-page .show-card.images-wrapper {
  flex-direction: column;
  justify-content: space-between;
}

.show-card-wrapper {
  max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto;
}

.show-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.show-card img.host-image {
  width: 100px;
}

.show-card img.show-logo {
  object-fit: contain;
}

@media(max-width: 1200px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

@media(max-height: 790px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

.show-card-wrapper .show-title {
  text-align: left;
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}

.shows-list .show-card img {
  transition: all .4s ease;
}

.shows-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

/* add show  */
.add-show-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #DDDDDD;
  border-radius: 5px;
  height: 100%;
  height: 150px;
  max-height: 150px;
  max-width: 300px;
  cursor: pointer;
}

.add-show-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

/* button */
.ant-btn-dashed.add-show-button,
.ant-btn-dashed.add-show-button:hover,
.ant-btn-dashed.add-show-button:focus,
.ant-btn-dashed.add-show-button:active {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.65);
  color: rgba(0, 0, 0, 0.65);
}

.ant-btn-dashed.add-show-button:hover {
  border-style: solid;
}

.add-show-form .ant-progress-outer {
  display: none;
}

@media(min-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+5) {
    margin-top: 50px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+4) {
    margin-top: 50px;
  }
}

@media(max-width: 768px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .show-card.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

/* Detail Page */
.shows-title-wrapper {
  padding: 20px;
  /* padding-top: 40px; */
}

.shows-detail-wrapper {
  padding: 20px 40px;
  padding-top: 40px;
}

.ant-page-header {
  padding: 20px;
}

.show-detail-page .show-description {
  height: 100%;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  /* word-break: break-all; */
}

.show-detail-page .show-description-wrapper {
  margin: 0px 20px;
}

/* .show-detail-page  .show-description-wrapper .ant-row-flex{
  padding: 0px 20px;
} */
.divider-wrapper {
  margin: 0px 20px !important;
}

.show-detail-page .show-author {
  font-size: 15px;
  font-weight: 600;
}

.show-detail-page .show-author span {
  font-size: 15px;
  margin-left: 3px;
  font-weight: 500;
}

.show-detail-page .show-card.images-wrapper {
  overflow: auto;
}

.show-detail-page .show-card.images-wrapper .show-author {
  margin: 10px 0;
}

.episode-loader-wrapper {
  position: fixed;
  bottom: 50px;
  left: 44.7%;
  z-index: 999;
  /* position: absolute;
  left: 50%;
  z-index: 1;
  bottom: 0; */
}

.box.show-all-images {
  height: calc(100vh - 180px);
}

.shows-list.season-filter {
  margin: 10px 24px;
  display: flex;
  justify-content: flex-end;  
  position: relative;
}

.shows-list.season-filter .ant-select {
  width: 250px;
}

.shows-list.season-filter .ant-select:nth-of-type(2) {
  margin-left: 10px;
}

.show-card-wrapper.season-card-details {
  position: relative;
}

.episode-details .season-card-details-wrapper {
  text-align: left;
}

.person-management-wrapper .season-podcastepisode-table-img {
  width: 50px;
  height: 50px;
  object-fit: cover; 
}

.person-management-wrapper .seasonepisode-update-weight-input {
  width: 100px;
}

.person-management-wrapper .season-episode-table-img {
  width: 68px;
  height: 40px;
  object-fit: cover;
}

.person-management-wrapper .seasonepisode-action-buttons-wrapper {
  margin-bottom: 24px;
}

.seasonepisode-action-buttons-wrapper .seasonepisode-action-header {
  justify-content: flex-end;
}

.seasonepisode-action-buttons-wrapper .search-input {
  width:324px
}

.seasonepisode-action-buttons-wrapper .action-btn .cancel-btn {
  margin-right: 10px;
}

.add-seasonepisode-wrapper {
  margin-bottom: 20px;
}

.season-podcast-card-col-wrapper {
  margin-bottom: 24px;
}

.episode-season-wrapper.podcast-episode-season-wrapper {
  padding: 0 10px;
}
.box-image {
  padding: 0;
}

.box-insider.box-image {
  padding: 1rem;
}

.mask {
  background-color: rgba(0, 0, 0, .7);
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.episode-wrapper {
  padding: 0 20px;
}

.live-segment-wrapper {
  padding-bottom: 20px;
}

.episode-title-wrapper {
  padding: 20px;
}

.episode-page-header {
  padding-bottom: 0;
}

.episode-number-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.episode-number-wrapper p {
  margin-bottom: 0;
}

.episode-number-wrapper input.ant-input {
  height: 24px;
}

.episode-wrapper .description {
  background: transparent;
  margin: 1em;
}

.episode-wrapper .description-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.3em;
}

/* .episode-list{
  max-height: calc(100vh - 141px - 105px - 64px - 50px);
  overflow: auto;
  padding: 20px;
	padding-top: 0;
}
@media(max-width: 1200px){
  .episode-list{
    max-height: calc(100vh - 141px - 105px - 64px - 80px);
  }
} */
.episode-list-wrapper {
  max-height: calc(100vh - 97px - 64px);
  overflow: auto;
}

.add-episode-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DDDDDD;
  border-radius: 5px;
  max-height: 150px;
  min-height: 120px;
  height: 150px;
  cursor: pointer;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.add-episode-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

@media(max-width: 767px) {
  .add-episode-wrapper {
    margin-bottom: 2rem;
  }
}

.episode-season-wrapper {
  padding: 0;
  position: relative;
  height: 100%;
}

.season-card-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  height: 220px;
  max-height: 240px;
  margin: 0 auto
}

.season-card-wrapper .ant-tag {
  position: absolute;
  right: 0;
  top: 10px;
}

.episode-card-col-wrapper {
  margin-bottom: 24px;
}

@media(min-width: 991px) {
  .episode-card-col-wrapper.ant-col-xl-6:nth-child(n+5) {
    margin-top: 40px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .episode-card-col-wrapper.ant-col-xl-6:nth-child(n+4) {
    margin-top: 40px;
  }
}

@media(max-width: 767px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .episode-card-col-wrapper.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

.episode-card img {
  max-width: 100%;
  border-radius: 5px;
  /* width: 300px; */
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.episode-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

.episode-details {
  margin-left: 0.3em;
}

.episode-title {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0;
}

.episode-date {
  color: rgb(39, 40, 50);
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.episode-description-div-wrapper {
  margin: 0px 20px;
}

.episode-description-div-wrapper .episode-card {
  display: flex;
  align-items: center;
  height: 100%;
}

.episode-description-wrapper {
  padding: 0px 20px;
}

.episode-description-wrapper .episode-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  object-fit: cover;
}

.episode-description-wrapper .episode-description {
  height: 100%;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  /* word-break: break-all; */
}

.episode-wrapper .title-wrapper h4 {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px;
}

.episode-wrapper .segmenet-title {
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .box-image .segmenet-title {
  color: white;
}

.episode-wrapper .upload-image-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .upload-image-wrapper button {
  background: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid;
  padding: 0;
  border-radius: 0;
}

.dropzone-wrapper {
  margin-bottom: 2em;
}

.dropzone-wrapper .ant-col {
  padding-left: 24px;
  padding-right: 24px;
}

.episode-wrapper .box-image .video-uploader .ant-progress-text,
.episode-wrapper .box-image .audio-uploader .ant-progress-text {
  color: white;
}

.episode-wrapper .video-uploader .ant-progress-text,
.episode-wrapper .audio-uploader .ant-progress-text {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card {
  background: transparent;
  width: 80%;
  margin: 0 auto;
  height: 180px;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .upload-image-wrapper button {
  color: white;
  font-weight: 600;
}

.episode-wrapper .play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  min-height: 180px;
  border: 1px dashed;
  border-width: 1.8px;
  border-color: #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.episode-wrapper .play-button span {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .box-image .play-button span,
.episode-wrapper .box-image .play-button button span,
.episode-wrapper .play-button button span {
  color: #fff;
}

.episode-wrapper .box-image .play-button {
  border-color: white;
}

.episode-wrapper .play-button .remove {
  position: absolute;
  top: -18px;
  right: -14px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .play-button.download-play-wrapper {
  flex-direction: column;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card:hover,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card:hover {
  border-width: 1.8px;
  border-color: white;
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card i,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card i {
  font-size: 38px;
  margin-bottom: 0.3em;
}

.episode-wrapper .parts-lists h4 {
  margin-bottom: 0;
}

.episode-wrapper .parts-lists .ant-list-item-meta-description {
  font-size: 12px;
}

.episode-wrapper .parts-lists i {
  font-size: 20px;
}

.episode-wrapper .parts-lists .ant-list-item {
  cursor: pointer;
  padding: 0.7em 1em;
  border-radius: 2px;
  min-height: 50px;
}

.episode-wrapper .parts-lists .ant-list-item.active {
  background: #e8e8e8;
}

.episode-wrapper .parts-lists .ant-list-item.uploading,
.add-segment-button.uploading {
  pointer-events: none;
}

.segment-live-table .ant-table-title {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant: tabular-nums;
  line-height: 1.5;
}

.segment-live-table i {
  cursor: pointer;
  font-size: 16px;
  transition: all .4s ease;
  color: #1890ff;
  margin-left: 5px;
}

.segment-live-table i:hover {
  -webkit-transform: translate3D(0, -1px, 0) scale(1.3);
          transform: translate3D(0, -1px, 0) scale(1.3);
  transition: all .4s ease;
}

.episode-wrapper .episode-live-details div {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.episode-wrapper .episode-live-details div span {
  font-weight: normal;
  margin-left: 0.3rem;
  word-break: break-all;
  /* color: #2094ff; */
}

.add-episode-form .ant-progress-outer {
  display: none;
}

.segment-more-button:hover {
  visibility: visible !important;
}

.episode-wrapper .ant-table-row:nth-child(even),
.episode-wrapper .ant-table-row:nth-child(odd),
.episode-wrapper .ant-table-thead>tr>th {
  background: transparent !important;
}

.episode-wrapper td:last-child {
  word-break: break-all;
}

.episode-wrapper .player-section-wrapper .box {
  height: 100%;
}

.episode-wrapper .player-section-wrapper,
.episode-wrapper .live-details-section-wrapper {
  padding-bottom: 20px;
}

@media(max-width: 991px) {
  .episode-wrapper .player-section-wrapper {
    width: 100%;
    margin-top: 30px;
  }

  .segment-details-wrapper {
    margin-top: 30px;
  }
}

.segment-details-wrapper .box,
.segment-lists-wrapper .box {
  height: 100%;
}

.episode-wrapper .ant-upload-list.ant-upload-list-picture {
  display: none;
}

.segment-live-image-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.segment-description {
  word-break: break-all;
  margin-top: 10px;
}

/* .live-player .video-react-time-control, .live-player .video-react-progress-control, .live-player .video-react .video-react-progress-control {
  display: none !important;
} */

.season-card-wrapper.season-podcast-card-wrapper {
  width: 200px;
  height: auto;
  max-height: none;
}

.episode-card.season-podcast-episode img {
  height: 200px;
  max-height: 200px;
  border-radius: 0;
}

.season-episode-delete-btn {
  position: absolute;
  right: 5px;
  top: 5px;  
}

.episode-description.season-episode-description {
  word-break: break-all;
}

.episode-description-div-wrapper.season-episode-description-div-wrapper {
  margin-bottom: 24px;
}

.season-title-wrapper {
  margin: 0 !important;
  margin-right: 10px !important;
}

.add-episode-wrapper.podcast-episode-add-btn {
  height: 200px;
  min-height: 200px;
  width: 200px;
  border-radius: 0;
  margin: 0 auto;
}
.episode-header {
  display: flex;
  justify-content: space-between;
}
.box-image {
  padding: 0;
}

.box-insider.box-image {
  padding: 1rem;
}

.mask {
  background-color: rgba(0, 0, 0, .7);
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.episode-wrapper {
  padding: 0 20px;
}

.live-segment-wrapper {
  padding-bottom: 20px;
}

.episode-title-wrapper {
  padding: 20px;
}

.episode-page-header {
  padding-bottom: 0;
}

.episode-number-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.episode-number-wrapper p {
  margin-bottom: 0;
}

.episode-number-wrapper input.ant-input {
  height: 24px;
}

.episode-wrapper .description {
  background: transparent;
  margin: 1em;
}

.episode-wrapper .description-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.3em;
}

/* .episode-list{
  max-height: calc(100vh - 141px - 105px - 64px - 50px);
  overflow: auto;
  padding: 20px;
	padding-top: 0;
}
@media(max-width: 1200px){
  .episode-list{
    max-height: calc(100vh - 141px - 105px - 64px - 80px);
  }
} */
.episode-list-wrapper {
  max-height: calc(100vh - 97px - 64px);
  overflow: auto;
}

.add-episode-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DDDDDD;
  border-radius: 5px;
  max-height: 150px;
  min-height: 120px;
  height: 150px;
  cursor: pointer;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.add-episode-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

@media(max-width: 767px) {
  .add-episode-wrapper {
    margin-bottom: 2rem;
  }
}

.episode-card-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.episode-card-wrapper .ant-tag {
  position: absolute;
  right: 0;
  top: 10px;
}

.video-card-col-wrapper {
  margin-bottom: 24px;
}

@media(min-width: 991px) {
  .video-card-col-wrapper.ant-col-xl-6:nth-child(n+5) {
    margin-top: 40px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .video-card-col-wrapper.ant-col-xl-6:nth-child(n+4) {
    margin-top: 40px;
  }
}

@media(max-width: 767px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .video-card-col-wrapper.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

.episode-card img {
  max-width: 100%;
  border-radius: 5px;
  /* width: 300px; */
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.episode-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

.episode-details {
  margin-left: 0.3em;
}

.episode-title {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0;
}

.episode-date {
  color: rgb(39, 40, 50);
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.episode-description-div-wrapper {
  margin: 0px 20px;
}

.episode-description-div-wrapper .episode-card {
  display: flex;
  align-items: center;
  height: 100%;
}

.episode-description-wrapper {
  padding: 0px 20px;
}

.episode-description-wrapper .episode-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  object-fit: cover;
}

.episode-description-wrapper .episode-description {
  /* height: 100%; */
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  /* word-break: break-all; */
}

.episode-description-wrapper .featured-image-link {
  text-decoration: underline;
  cursor: pointer;
}

.episode-wrapper .title-wrapper h4 {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px;
}

.episode-wrapper .segmenet-title {
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .box-image .segmenet-title {
  color: white;
}

.episode-wrapper .upload-image-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .upload-image-wrapper button {
  background: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid;
  padding: 0;
  border-radius: 0;
}

.dropzone-wrapper {
  margin-bottom: 2em;
}

.dropzone-wrapper .ant-col {
  padding-left: 24px;
  padding-right: 24px;
}

.episode-wrapper .box-image .video-uploader .ant-progress-text,
.episode-wrapper .box-image .audio-uploader .ant-progress-text {
  color: white;
}

.episode-wrapper .video-uploader .ant-progress-text,
.episode-wrapper .audio-uploader .ant-progress-text {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card {
  background: transparent;
  width: 80%;
  margin: 0 auto;
  height: 180px;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .upload-image-wrapper button {
  color: white;
  font-weight: 600;
}

.episode-wrapper .play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  min-height: 180px;
  border: 1px dashed;
  border-width: 1.8px;
  border-color: #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.episode-wrapper .play-button span {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .box-image .play-button span,
.episode-wrapper .box-image .play-button button span,
.episode-wrapper .play-button button span {
  color: #fff;
}

.episode-wrapper .box-image .play-button {
  border-color: white;
}

.episode-wrapper .play-button .remove {
  position: absolute;
  top: -18px;
  right: -14px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .play-button.download-play-wrapper {
  flex-direction: column;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card:hover,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card:hover {
  border-width: 1.8px;
  border-color: white;
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card i,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card i {
  font-size: 38px;
  margin-bottom: 0.3em;
}

.episode-wrapper .parts-lists h4 {
  margin-bottom: 0;
}

.episode-wrapper .parts-lists .ant-list-item-meta-description {
  font-size: 12px;
}

.episode-wrapper .parts-lists i {
  font-size: 20px;
}

.episode-wrapper .parts-lists .ant-list-item {
  cursor: pointer;
  padding: 0.7em 1em;
  border-radius: 2px;
  min-height: 50px;
}

.episode-wrapper .parts-lists .ant-list-item.active {
  background: #e8e8e8;
}

.episode-wrapper .parts-lists .ant-list-item.uploading,
.add-segment-button.uploading {
  pointer-events: none;
}

.segment-live-table .ant-table-title {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant: tabular-nums;
  line-height: 1.5;
}

.segment-live-table i {
  cursor: pointer;
  font-size: 16px;
  transition: all .4s ease;
  color: #1890ff;
  margin-left: 5px;
}

.segment-live-table i:hover {
  -webkit-transform: translate3D(0, -1px, 0) scale(1.3);
          transform: translate3D(0, -1px, 0) scale(1.3);
  transition: all .4s ease;
}

.episode-wrapper .episode-live-details div {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.episode-wrapper .episode-live-details div span {
  font-weight: normal;
  margin-left: 0.3rem;
  word-break: break-all;
  /* color: #2094ff; */
}

.add-episode-form .ant-progress-outer {
  display: none;
}

.segment-more-button:hover {
  visibility: visible !important;
}

.episode-wrapper .ant-table-row:nth-child(even),
.episode-wrapper .ant-table-row:nth-child(odd),
.episode-wrapper .ant-table-thead>tr>th {
  background: transparent !important;
}

.episode-wrapper td:last-child {
  word-break: break-all;
}

.episode-wrapper .player-section-wrapper .box {
  height: 100%;
}

.episode-wrapper .player-section-wrapper,
.episode-wrapper .live-details-section-wrapper {
  padding-bottom: 20px;
}

@media(max-width: 991px) {
  .episode-wrapper .player-section-wrapper {
    width: 100%;
    margin-top: 30px;
  }

  .segment-details-wrapper {
    margin-top: 30px;
  }
}

.segment-details-wrapper .box,
.segment-lists-wrapper .box {
  height: 100%;
}

.episode-wrapper .ant-upload-list.ant-upload-list-picture {
  display: none;
}

.segment-live-image-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.segment-description {
  word-break: break-all;
  margin-top: 10px;
}

/* .live-player .video-react-time-control, .live-player .video-react-progress-control, .live-player .video-react .video-react-progress-control {
  display: none !important;
} */

.video-subtitle-divider{
  margin: 0 4px;
}

.add-video-subtitles-form .d-none.ant-form-item {
  display: none;
}

.add-video-subtitles-form .remove-video-subtitle-icon {
  margin-top: 1.8rem;
}

.add-video-subtitles-form .submit-video-subtitle-btn {
  margin-right: 20%;
}

.show-detail-page {
  height: 100%;
}

.shows-wrapper {
  padding: 20px;
}

.show-detail-page .show-card {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.video-list-wrapper {
  /* max-height: calc(100vh - 97px - 64px); */
  overflow: auto;
}

.show-detail-page .show-card.images-wrapper {
  flex-direction: column;
  justify-content: space-between;
}

.show-card-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto;
}

.show-card-wrapper .absolute-container {
  position: absolute;
  right: 10px;
  z-index: 111;
  top: 10px;
}

.show-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.show-card img.host-image {
  width: 100px;
}

.show-card img.show-logo {
  object-fit: contain;
}

@media(max-width: 1200px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

@media(max-height: 790px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

.show-card-wrapper .show-title {
  text-align: left;
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}

.shows-list .show-card img {
  transition: all .4s ease;
}

.shows-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

/* add show  */
.add-show-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #DDDDDD;
  border-radius: 5px;
  height: 100%;
  height: 150px;
  max-height: 150px;
  max-width: 300px;
  cursor: pointer;
}

.add-show-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

/* button */
.ant-btn-dashed.add-show-button,
.ant-btn-dashed.add-show-button:hover,
.ant-btn-dashed.add-show-button:focus,
.ant-btn-dashed.add-show-button:active {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.65);
  color: rgba(0, 0, 0, 0.65);
}

.ant-btn-dashed.add-show-button:hover {
  border-style: solid;
}

.add-show-form .ant-progress-outer {
  display: none;
}

@media(min-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+5) {
    margin-top: 50px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+4) {
    margin-top: 50px;
  }
}

@media(max-width: 768px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .show-card.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

/* Detail Page */
.shows-title-wrapper {
  padding: 20px;
  /* padding-top: 40px; */
}

.shows-detail-wrapper {
  padding: 20px 40px;
  padding-top: 40px;
}

.ant-page-header {
  padding: 20px;
}

.show-detail-page .show-description {
  height: 100%;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  /* word-break: break-all; */
}

.show-detail-page .show-description-wrapper {
  margin: 0px 20px;
}

/* .show-detail-page  .show-description-wrapper .ant-row-flex{
  padding: 0px 20px;
} */
.divider-wrapper {
  margin: 0px 20px !important;
}

.show-detail-page .show-author {
  font-size: 15px;
  font-weight: 600;
}

.show-detail-page .show-author span {
  font-size: 15px;
  margin-left: 3px;
  font-weight: 500;
}

.show-detail-page .show-card.images-wrapper {
  overflow: auto;
}

.show-detail-page .show-card.images-wrapper .show-author {
  margin: 10px 0;
}

.episode-loader-wrapper {
  position: fixed;
  bottom: 50px;
  left: 44.7%;
  z-index: 999;
  /* position: absolute;
  left: 50%;
  z-index: 1;
  bottom: 0; */
}

.box.show-all-images {
  height: calc(100vh - 180px);
}
.show-detail-page {
  height: 100%;
}

.shows-wrapper {
  padding: 20px;
}

.show-detail-page .show-card {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.show-detail-page .show-card.images-wrapper {
  flex-direction: column;
  justify-content: space-between;
}

.show-card-wrapper {
  max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto;
}

.show-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.show-card img.host-image {
  width: 100px;
}

.show-card img.show-logo {
  object-fit: contain;
}

@media(max-width: 1200px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

@media(max-height: 790px) {
  .show-detail-page .show-card.images-wrapper img {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
}

.show-card-wrapper .show-title {
  text-align: left;
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}

.shows-list .show-card img {
  transition: all .4s ease;
}

.shows-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

/* add show  */
.add-show-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #DDDDDD;
  border-radius: 5px;
  height: 100%;
  height: 150px;
  max-height: 150px;
  max-width: 300px;
  cursor: pointer;
}

.add-show-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

/* button */
.ant-btn-dashed.add-show-button,
.ant-btn-dashed.add-show-button:hover,
.ant-btn-dashed.add-show-button:focus,
.ant-btn-dashed.add-show-button:active {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.65);
  color: rgba(0, 0, 0, 0.65);
}

.ant-btn-dashed.add-show-button:hover {
  border-style: solid;
}

.add-show-form .ant-progress-outer {
  display: none;
}

@media(min-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+5) {
    margin-top: 50px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .show-card.ant-col-xl-6:nth-child(n+4) {
    margin-top: 50px;
  }
}

@media(max-width: 768px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .show-card.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

/* Detail Page */
.shows-title-wrapper {
  padding: 20px;
  /* padding-top: 40px; */
}

.shows-detail-wrapper {
  padding: 20px 40px;
  padding-top: 40px;
}

.ant-page-header {
  padding: 20px;
}

.show-detail-page .show-description {
  height: 100%;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  /* word-break: break-all; */
}

.show-detail-page .show-description-wrapper {
  margin: 0px 20px;
}

/* .show-detail-page  .show-description-wrapper .ant-row-flex{
  padding: 0px 20px;
} */
.divider-wrapper {
  margin: 0px 20px !important;
}

.show-detail-page .show-author {
  font-size: 15px;
  font-weight: 600;
}

.show-detail-page .show-author span {
  font-size: 15px;
  margin-left: 3px;
  font-weight: 500;
}

.show-detail-page .show-card.images-wrapper {
  overflow: auto;
}

.show-detail-page .show-card.images-wrapper .show-author {
  margin: 10px 0;
}

.episode-loader-wrapper {
  position: fixed;
  bottom: 50px;
  left: 44.7%;
  z-index: 999;
  /* position: absolute;
  left: 50%;
  z-index: 1;
  bottom: 0; */
}

.box.show-all-images {
  height: calc(100vh - 180px);
}

.add-clip-subtitles-form .d-none.ant-form-item {
  display: none;
}

.add-clip-subtitles-form .remove-clip-subtitle-icon {
  margin-top: 1.8rem;
}

.add-clip-subtitles-form .submit-clip-subtitle-btn {
  margin-right: 20%;
}

.clip-subtitle-divider{
  margin: 0 4px;
}

.box-image {
  padding: 0;
}

.box-insider.box-image {
  padding: 1rem;
}

.mask {
  background-color: rgba(0, 0, 0, .7);
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.episode-wrapper {
  padding: 0 20px;
}

.live-segment-wrapper {
  padding-bottom: 20px;
}

.episode-title-wrapper {
  padding: 20px;
}

.episode-page-header {
  padding-bottom: 0;
}

.episode-number-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.episode-number-wrapper p {
  margin-bottom: 0;
}

.episode-number-wrapper input.ant-input {
  height: 24px;
}

.episode-wrapper .description {
  background: transparent;
  margin: 1em;
}

.episode-wrapper .description-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.3em;
}

/* .episode-list{
  max-height: calc(100vh - 141px - 105px - 64px - 50px);
  overflow: auto;
  padding: 20px;
	padding-top: 0;
}
@media(max-width: 1200px){
  .episode-list{
    max-height: calc(100vh - 141px - 105px - 64px - 80px);
  }
} */
.episode-list-wrapper {
  max-height: calc(100vh - 97px - 64px);
  overflow: auto;
}

.add-episode-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DDDDDD;
  border-radius: 5px;
  max-height: 150px;
  min-height: 120px;
  height: 150px;
  cursor: pointer;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.add-episode-wrapper:hover .ant-btn-dashed.add-show-button {
  border-style: solid;
}

@media(max-width: 767px) {
  .add-episode-wrapper {
    margin-bottom: 2rem;
  }
}

.episode-card-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.episode-card-wrapper .ant-tag {
  position: absolute;
  right: 0;
  top: 10px;
}

.clips-card-col-wrapper {
  margin-bottom: 24px;
}

@media(min-width: 991px) {
  .clips-card-col-wrapper.ant-col-xl-6:nth-child(n+5) {
    margin-top: 40px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .clips-card-col-wrapper.ant-col-xl-6:nth-child(n+4) {
    margin-top: 40px;
  }
}

@media(max-width: 767px) {
  .add-show-wrapper {
    height: 150px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .clips-card-col-wrapper.ant-col-xl-6:nth-child(n+2) {
    margin-top: 40px;
    text-align: center;
  }
}

.episode-card img {
  max-width: 100%;
  border-radius: 5px;
  /* width: 300px; */
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.episode-list .show-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

.episode-details {
  margin-left: 0.3em;
}

.episode-title {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0;
}

.episode-date {
  color: rgb(39, 40, 50);
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.episode-description-div-wrapper {
  margin: 0px 20px;
}

.episode-description-div-wrapper .episode-card {
  display: flex;
  align-items: center;
  height: 100%;
}

.episode-description-wrapper {
  padding: 0px 20px;
}

.episode-description-wrapper .episode-card img {
  max-width: 100%;
  border-radius: 5px;
  width: 300px;
  height: 150px;
  max-height: 150px;
  object-fit: cover;
}

.episode-description-wrapper .episode-description {
  /* height: 100%; */
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  /* word-break: break-all; */
}

.episode-description-wrapper .featured-image-link {
  text-decoration: underline;
  cursor: pointer;
}

.episode-wrapper .title-wrapper h4 {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px;
}

.episode-wrapper .segmenet-title {
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .box-image .segmenet-title {
  color: white;
}

.episode-wrapper .upload-image-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2em;
}

.episode-wrapper .upload-image-wrapper button {
  background: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid;
  padding: 0;
  border-radius: 0;
}

.dropzone-wrapper {
  margin-bottom: 2em;
}

.dropzone-wrapper .ant-col {
  padding-left: 24px;
  padding-right: 24px;
}

.episode-wrapper .box-image .video-uploader .ant-progress-text,
.episode-wrapper .box-image .audio-uploader .ant-progress-text {
  color: white;
}

.episode-wrapper .video-uploader .ant-progress-text,
.episode-wrapper .audio-uploader .ant-progress-text {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card {
  background: transparent;
  width: 80%;
  margin: 0 auto;
  height: 180px;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card .ant-upload,
.episode-wrapper .box-image .upload-image-wrapper button {
  color: white;
  font-weight: 600;
}

.episode-wrapper .play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  min-height: 180px;
  border: 1px dashed;
  border-width: 1.8px;
  border-color: #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.episode-wrapper .play-button span {
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .box-image .play-button span,
.episode-wrapper .box-image .play-button button span,
.episode-wrapper .play-button button span {
  color: #fff;
}

.episode-wrapper .box-image .play-button {
  border-color: white;
}

.episode-wrapper .play-button .remove {
  position: absolute;
  top: -18px;
  right: -14px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
}

.episode-wrapper .play-button.download-play-wrapper {
  flex-direction: column;
}

.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card,
.episode-wrapper .box-image .video-uploader .ant-upload.ant-upload-select-picture-card:hover,
.episode-wrapper .box-image .audio-uploader .ant-upload.ant-upload-select-picture-card:hover {
  border-width: 1.8px;
  border-color: white;
}

.episode-wrapper .video-uploader .ant-upload.ant-upload-select-picture-card i,
.episode-wrapper .audio-uploader .ant-upload.ant-upload-select-picture-card i {
  font-size: 38px;
  margin-bottom: 0.3em;
}

.episode-wrapper .parts-lists h4 {
  margin-bottom: 0;
}

.episode-wrapper .parts-lists .ant-list-item-meta-description {
  font-size: 12px;
}

.episode-wrapper .parts-lists i {
  font-size: 20px;
}

.episode-wrapper .parts-lists .ant-list-item {
  cursor: pointer;
  padding: 0.7em 1em;
  border-radius: 2px;
  min-height: 50px;
}

.episode-wrapper .parts-lists .ant-list-item.active {
  background: #e8e8e8;
}

.episode-wrapper .parts-lists .ant-list-item.uploading,
.add-segment-button.uploading {
  pointer-events: none;
}

.segment-live-table .ant-table-title {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant: tabular-nums;
  line-height: 1.5;
}

.segment-live-table i {
  cursor: pointer;
  font-size: 16px;
  transition: all .4s ease;
  color: #1890ff;
  margin-left: 5px;
}

.segment-live-table i:hover {
  -webkit-transform: translate3D(0, -1px, 0) scale(1.3);
          transform: translate3D(0, -1px, 0) scale(1.3);
  transition: all .4s ease;
}

.episode-wrapper .episode-live-details div {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.episode-wrapper .episode-live-details div span {
  font-weight: normal;
  margin-left: 0.3rem;
  word-break: break-all;
  /* color: #2094ff; */
}

.add-episode-form .ant-progress-outer {
  display: none;
}

.segment-more-button:hover {
  visibility: visible !important;
}

.episode-wrapper .ant-table-row:nth-child(even),
.episode-wrapper .ant-table-row:nth-child(odd),
.episode-wrapper .ant-table-thead>tr>th {
  background: transparent !important;
}

.episode-wrapper td:last-child {
  word-break: break-all;
}

.episode-wrapper .player-section-wrapper .box {
  height: 100%;
}

.episode-wrapper .player-section-wrapper,
.episode-wrapper .live-details-section-wrapper {
  padding-bottom: 20px;
}

@media(max-width: 991px) {
  .episode-wrapper .player-section-wrapper {
    width: 100%;
    margin-top: 30px;
  }

  .segment-details-wrapper {
    margin-top: 30px;
  }
}

.segment-details-wrapper .box,
.segment-lists-wrapper .box {
  height: 100%;
}

.episode-wrapper .ant-upload-list.ant-upload-list-picture {
  display: none;
}

.segment-live-image-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.segment-description {
  word-break: break-all;
  margin-top: 10px;
}

/* .live-player .video-react-time-control, .live-player .video-react-progress-control, .live-player .video-react .video-react-progress-control {
  display: none !important;
} */
.modular-page-wrapper {
  height: 100%;
  padding: 20px 20px;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.title-wrapper h2.ant-typography {
  margin-bottom: 0;
}

.modular-page-form-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;

  background: #dddddd;
  box-shadow: inset 0px 0px 0px #bcc7d3;
  border-radius: 5px 5px;
}

.modular-page-form-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 40px 48px; */

  background: #dddddd;
  box-shadow: inset 0px 0px 0px #bcc7d3;
}

.premiere-image-override {
  position: absolute;
  right: 10px;
  z-index: 111;
  top: 8px;
}

.premiere-image-override-wrapper {
  position: relative;
  /* max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto; */
}

.modular-page-wrapper .ant-pagination {
  text-align: right;
  margin-top: 20px;
}

.modular-page-wrapper-body {
  overflow: auto;
  height: calc(100vh - 150px);
  padding-right: 12px;
}

.tile-card-episode-wrapper,
.tile-card-video-wrapper,
.tile-card-clip-wrapper,
.tile-card-show-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.tile-card-premiere-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto;
}

.tile-card-premiere-col-wrapper {
  padding-right: 18px;
}

.tile-card-premiere-img-wrapper {
  object-fit: cover;
  border-radius: 5px;
  max-width: 100%;
  width: 100%;
  max-height: 150px;
  min-height: 150px;
}

.tile-card-premiere-text-wrapper {
  display: flex;
  flex-direction: column;
}

.tile-card-episode-wrapper .ant-tag,
.tile-card-video-wrapper .ant-tag,
.tile-card-clip-wrapper .ant-tag,
.tile-card-show-wrapper .ant-tag {
  position: absolute;
  right: 0;
  top: 10px;
}

@media (min-width: 991px) {
  .tile-card-episode-col-wrapper.ant-col-xl-6:nth-child(n + 5),
  .tile-card-video-col-wrapper.ant-col-xl-6:nth-child(n + 5),
  .tile-card-clip-col-wrapper.ant-col-xl-6:nth-child(n + 5),
  .tile-card-show-col-wrapper.ant-col-xl-6:nth-child(n + 5) {
    margin-top: 40px;
  }

  .tile-card-podcast-col-wrapper.ant-col-xl-4:nth-child(n + 7),
  .tile-card-podcastepisode-col-wrapper.ant-col-xl-4:nth-child(n + 7) {
    margin-top: 40px;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .tile-card-episode-col-wrapper.ant-col-xl-6:nth-child(n + 4),
  .tile-card-video-col-wrapper.ant-col-xl-6:nth-child(n + 4),
  .tile-card-clip-col-wrapper.ant-col-xl-6:nth-child(n + 4),
  .tile-card-show-col-wrapper.ant-col-xl-6:nth-child(n + 4) {
    margin-top: 40px;
  }

  .tile-card-podcast-col-wrapper.ant-col-xl-4:nth-child(n + 5),
  .tile-card-podcastepisode-col-wrapper.ant-col-xl-4:nth-child(n + 5) {
    margin-top: 40px;
  }
  .tile-card-podcast img,
  .tile-card-podcastepisode img {
    min-height: 130px !important;
    max-height: 130px !important;
  }
}

@media (max-width: 767px) {
  .tile-card-episode-col-wrapper.ant-col-xl-6:nth-child(n + 2),
  .tile-card-video-col-wrapper.ant-col-xl-6:nth-child(n + 2),
  .tile-card-clip-col-wrapper.ant-col-xl-6:nth-child(n + 2),
  .tile-card-show-col-wrapper.ant-col-xl-6:nth-child(n + 2) {
    margin-top: 40px;
    text-align: center;
  }

  .tile-card-podcast-col-wrapper.ant-col-xl-4:nth-child(n + 2),
  .tile-card-podcastepisode-col-wrapper.ant-col-xl-4:nth-child(n + 2) {
    margin-top: 40px;
    text-align: center;
  }
}

.tile-card-episode img,
.tile-card-video img,
.tile-card-clip img,
.tile-card-show img {
  max-width: 100%;
  border-radius: 5px;
  /* width: 300px; */
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.tile-details-episode,
.tile-details-video,
.tile-details-clip,
.tile-details-show {
  margin-left: 0.3em;
  text-align: left;
}

.tile-title-episode,
.tile-title-video,
.tile-title-clip,
.tile-title-show {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0;
  line-height: 1.5em;
  max-height: 4.5em;
}

.image-override-margin-btn {
  margin-top: 24px;
}

.tile-card-delete {
  position: absolute;
  right: 10px;
  z-index: 111;
  top: 8px;
}

.table-image {
  width: 68px;
  height: 40px;
  object-fit: cover;
}
.table-name {
  word-wrap: break-word;
  word-break: break-word;
  text-align: left;
}

.carousel-card-footer-wrapper {
  text-align: left;
  margin-left: 25px;
}

.carousel-card {
  border-radius: 5px 5px 0px 0px;
}

.page-change-btn-wrapper {
  margin-top: 10px;
  float: right;
}

#modular-page-form .ant-progress-outer {
  display: none;
}

#modular-page-form .d-none.ant-form-item {
  display: none;
}

#modular-page-form .mb-0.ant-form-item {
  margin-bottom: 0px;
}

.page-change-btn-wrapper{
  float: right;
  margin-top: 10px;
}


.tile-card-podcast-wrapper,
.tile-card-podcastepisode-wrapper {
  position: relative;
  max-width: 100%;
  width: 200px;
  margin: 0 auto;
}

.tile-card-podcast img,
.tile-card-podcastepisode img {
  max-width: 100%;
  width: 100%;
  max-height: 170px;
  min-height: 170px;
  object-fit: cover;
}

.tile-details-podcast,
.tile-details-podcastepisode {
  margin-left: 0.3em;
  text-align: left;
}

.tile-title-podcast,
.tile-title-podcastepisode {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0;
  line-height: 1.5em;
  max-height: 4.5em;
}

.tile-card-podcast-premiere-img-wrapper {
  max-width: 100%;
  width: 200px;
  min-width: 100%;
  max-height: 170px;
  min-height: 170px;
  object-fit: cover;
}

.tile-card-podcast-premiere-wrapper {
  position: relative;
  max-width: 100%;
  width: 200px;
  margin: 0 auto;
}

img.podcast-upload-image {
  max-width: 100%;
  width: 100%;
  max-height: 170px;
  min-height: 170px;
  object-fit: cover;
  border-radius: 0;
}

.modular-page-select-episode-filter {
  width: 250px !important;
}  
.show-carousel-wrapper {
  max-width: 100%;
}

.show-carousel-landscape-card-wrapper {
  position: relative;
  max-width: 100%;
  height: 150px;
  width: 300px;
}

.show-carousel-portrait-card-wrapper {
  position: relative;
  max-width: 100%;
  width: 127px;
  height: 227px;
}

.show-carousel-card img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  max-width: 100%;
  max-height: 225px;
  min-height: 225px;
}

.show-carousel-card-delete {
  position: absolute;
  right: 10px;
  z-index: 111;
  top: 8px;
}

.show-carousel-lanscape-detail {
  width: 100%;
}

.show-carousel-lanscape-title {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-carousel-title {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 24px;
}

.show-carousel-detail {
  text-align: left;
}

.show-carousel-card-col-wrapper {
  margin-bottom: 24px;
}

.show-carousel-portrait-card-col-wrapper {
  margin-bottom: 60px;
}

@media (min-width: 1200) {
  .show-carousel-portrait-card-col-wrapper:nth-child(n + 9) {
    margin-top: 60px;
  }
}

@media (min-width: 992px) and (max-width: 1199) {
  .show-carousel-card-col-wrapper.ant-col-xl-6:nth-child(n + 5) {
    margin-top: 40px;
  }
  .show-carousel-portrait-card-col-wrapper:nth-child(n + 8) {
    margin-top: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .show-carousel-card-col-wrapper.ant-col-xl-6:nth-child(n + 4) {
    margin-top: 20px;
  }
  .show-carousel-portrait-card-col-wrapper:nth-child(n + 5) {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .show-carousel-card-col-wrapper.ant-col-xl-6:nth-child(n + 2) {
    margin-top: 20px;
  }
  .show-carousel-portrait-card-col-wrapper:nth-child(n + 2) {
    margin-top: 60px;
  }
}

.login-wrapper{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper .login-form-wrapper{
  padding: 50px;
  border-radius: 5px;
  background: #f2f2f2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  /* transform: translate3D(0,-1px,0) scale(1.02);
  transition: all .4s ease; */
}
.login-wrapper .login-form-wrapper .header{
  margin-bottom: 20px;
}
.login-wrapper .login-form-wrapper .body{
  margin-top: 20px;
  text-align: center;
}

#root {
  height: 100vh;
  font-family: "Montserrat";
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  border: none;
}
.content-wrapper.ant-layout-content {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  max-width: 100%;
  overflow-x: hidden;
}
.fullscreen {
  height: 100%;
  background: #f0f3f5;
}
img {
  max-width: 100%;
}
.d-flex {
  display: flex;
}
.box {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  /* margin-bottom: 1rem; */
  padding: 1rem;
  background: #fff;
}
.text-center {
  text-align: center;
}
.border-bottom {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 0.5em;
}
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.callback-wrapper {
  width: 100%;
}
.callback-wrapper .loader-wrapper {
  height: 100vh;
}
.ant-table-row:nth-child(even),
.ant-table-thead > tr > th {
  background: #eeeeee;
}
.ant-table-row:nth-child(odd) {
  background: #f6f6f6;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
  line-height: 1;
  margin-bottom: 16px;
}

/* EPISODE STAUS */
.ant-tag.IDLE {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag.DRAFT {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag.SCHEDULED,
.ant-tag.RECORDING,
.ant-tag.UPCOMING {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag.LIVE,
.ant-tag.OPEN,
.ant-tag.LIVE_FINISHED,
.ant-tag.CONNECTED {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag.PUBLISHED {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag.UNPUBLISHED,
.ant-tag.DISCONNECTED,
.ant-tag.ENDED,
.ant-tag.ERROR {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
/* END - EPISODE STAUS */

.ant-calendar-time-picker-btn {
  text-transform: capitalize;
}
.ant-calendar-ok-btn {
  text-transform: uppercase;
}
/* Upload */
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  padding-left: 80px;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 80px;
}
/* END - Upload */

/* FONT WEIGHT */
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
/* END - FONT WEIGHT */

/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* 404 */
.wrapper-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.wrapper-404 .title-404 {
  font-size: 10rem;
  font-weight: 700;
}
.wrapper-404 .subtitle-404 {
  font-weight: 700;
  font-size: 200%;
  margin-bottom: 2rem;
  line-height: 1.2;
}
.wrapper-404 .description-404 {
  margin-bottom: 10rem;
}
/* END - 404 */

/* FullScreen */
.fullscreen-mode aside,
.fullscreen-mode .header {
  display: none;
}
.fullscreen-mode .fullscreen {
  object-fit: contain;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  box-sizing: border-box !important;
  min-width: 0px !important;
  max-width: none !important;
  min-height: 0px !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-transform: none !important;
          transform: none !important;
  margin: 0px !important;
}
/* END - Fullscreen */

.break-word {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.m-2 {
  margin: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-5 {
  margin-top: 0.8rem;
}

.p-2 {
  padding: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.p-0 {
  padding: 0;
}

.line-height-1 {
  line-height: 1.5;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

