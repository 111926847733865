.podcast-detail-page {
  height: 100%;
}

.podcasts-wrapper {
  padding: 20px;
}

.podcast-detail-page .podcast-card {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.podcast-detail-page .podcast-card.images-wrapper {
  flex-direction: column;
  justify-content: space-between;
}

.podcast-card-wrapper {
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.podcast-card img {
  max-width: 100%;
  width: 100%;
  height: 180px;
  max-height: 180px;
  min-height: 180px;
  object-fit: cover;
}

.podcast-card img.podcast-image {
  width: 150px;
}

.podcast-card img.podcast-logo {
  object-fit: contain;
}

@media(max-width: 1200px) {
  .podcast-detail-page .podcast-card.images-wrapper .podcast-background-image,
  .podcast-detail-page .podcast-card.images-wrapper .podcast-logo-image {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }

  .podcast-detail-page .podcast-card.images-wrapper .podcast-cover-image {
    max-width: 130px;
    max-height: 130px;
    min-height: 50px;
  }
}

@media(max-height: 790px) {
  .podcast-detail-page .podcast-card.images-wrapper .podcast-background-image,
  .podcast-detail-page .podcast-card.images-wrapper .podcast-logo-image {
    max-width: 170px;
    max-height: 100px;
    min-height: 50px;
  }
  .podcast-detail-page .podcast-card.images-wrapper .podcast-cover-image {
    max-width: 130px;
    max-height: 130px;
    min-height: 50px;
  }
}

.podcast-card-wrapper .podcast-title {
  text-align: left;
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}

.podcasts-list .podcast-card img {
  transition: all .4s ease;
}

.podcasts-list .podcast-card img:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
  transform: translate3D(0, -1px, 0) scale(1.02);
  transition: all .4s ease;
}

/* add show  */
.add-podcast-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #DDDDDD;
  height: 100%;
  height: 180px;
  max-height: 180px;
  max-width: 200px;
  cursor: pointer;
}

.add-podcast-wrapper:hover .ant-btn-dashed.add-podcast-button {
  border-style: solid;
}

/* button */
.ant-btn-dashed.add-podcast-button,
.ant-btn-dashed.add-podcast-button:hover,
.ant-btn-dashed.add-podcast-button:focus,
.ant-btn-dashed.add-podcast-button:active {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.65);
  color: rgba(0, 0, 0, 0.65);
}

.ant-btn-dashed.add-podcast-button:hover {
  border-style: solid;
}

.add-podcast-form .ant-progress-outer {
  display: none;
}

@media(min-width: 991px) {
  .podcast-card.ant-col-xl-4:nth-child(n+7) {
    margin-top: 24px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .podcast-card.ant-col-xl-4:nth-child(n+4) {
    margin-top: 24px;
  }
}

@media(max-width: 768px) {
  .add-podcast-wrapper {
    height: 200px;
    width: 300px;
    align-self: center;
    margin: 0 auto;
  }

  .podcast-card.ant-col-xl-4:nth-child(n+2) {
    margin-top: 24px;
    text-align: center;
  }
  
  .podcast-card-wrapper .podcast-title {
    text-align: center;
  }
}

/* Detail Page */
.podcasts-title-wrapper {
  padding: 20px;
}

.shows-detail-wrapper {
  padding: 20px 40px;
  padding-top: 40px;
}

.ant-page-header {
  padding: 20px;
}

.podcast-detail-page .podcast-description {
  height: 100%;
  font-size: 15px;
  padding-top: 10px;
  font-weight: 400;
  word-break: break-all;
}

.podcast-detail-page .podcast-description-wrapper {
  margin: 0px 20px;
}

.divider-wrapper {
  margin: 0px 20px !important;
}

.podcast-detail-page .show-author {
  font-size: 15px;
  font-weight: 600;
}

.podcast-detail-page .show-author span {
  font-size: 15px;
  margin-left: 3px;
  font-weight: 500;
}

.podcast-detail-page .podcast-card.images-wrapper {
  overflow: auto;
}

.podcast-detail-page .podcast-card.images-wrapper .show-author {
  margin: 10px 0;
}

.episode-loader-wrapper {
  position: fixed;
  bottom: 50px;
  left: 44.7%;
  z-index: 999;
}

.podcast-loader-card .podcast-loader-card-wrapper {
  max-width: 100%;
  width: 200px;
  height: 180px;
  max-height: 180px;
  margin: 0 auto;
}

.ant-page-header.podcast-pageheader-wordbreak {
  display: flex;
}

.ant-page-header.podcast-pageheader-wordbreak .ant-page-header-heading {
  width: calc(100% - 220px);
}

.ant-page-header.podcast-pageheader-wordbreak .ant-page-header-heading-title {
  word-break: break-all;
  display: inline;
}