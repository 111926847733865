.badge-description {
  word-break: break-all;
  max-width: 300px;
}

.badgemgnt-table-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover; 
}

.badgemgnt-edit-btn {
  font-size: 14px !important;
  margin-left: 8px !important;
}

.page-change-btn-wrapper{
  float: right;
  margin-top: 10px;
}