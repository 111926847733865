.live-chat-wrapper{
  padding: 20px;
}
.title-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.title-wrapper h2.ant-typography{
  margin-bottom: 0;
}

.livechat-detailpage-wrapper{
  height: 100%;
}
.title-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.title-wrapper h2.ant-typography{
  margin-bottom: 0;
}

.livechat-subject{
  color: rgba(0, 0, 0, 0.65);
}
.livechat-detailpage-wrapper .livechat-description{
  margin: 20px 0;
  margin-bottom: 0;
}
.livechat-image{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.livechat-image p{
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}
.livechat-wrapper .ant-calendar-picker-input.ant-input{
  padding: 4px;
  font-size: 13px;
}
/* .live-chat-wrapper .ant-table-row td:first-child,
.live-chat-wrapper .ant-table-row td:first-child a{

} */
.live-chat-wrapper .ant-table-row td:first-child:hover, .live-chat-wrapper .ant-table-row td:first-child:hover a {
  cursor: pointer !important;
  color: #40a9ff;
}
.live-chat-wrapper .ant-pagination{
  text-align: right;
  margin-top: 20px;
}
.live-chat-wrapper .page-change-btn-wrapper {
  float: right;
  margin-top: 10px;
}
.live-chat-wrapper .link-button {
  padding: 0;
}
.live-chat-wrapper .link-button-icon {
  font-size: 14px; 
  margin-left: 8px;
}