.modular-page-wrapper {
  height: 100%;
  padding: 20px 20px;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.title-wrapper h2.ant-typography {
  margin-bottom: 0;
}

.modular-page-form-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;

  background: #dddddd;
  box-shadow: inset 0px 0px 0px #bcc7d3;
  border-radius: 5px 5px;
}

.modular-page-form-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 40px 48px; */

  background: #dddddd;
  box-shadow: inset 0px 0px 0px #bcc7d3;
}

.premiere-image-override {
  position: absolute;
  right: 10px;
  z-index: 111;
  top: 8px;
}

.premiere-image-override-wrapper {
  position: relative;
  /* max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto; */
}

.modular-page-wrapper .ant-pagination {
  text-align: right;
  margin-top: 20px;
}

.modular-page-wrapper-body {
  overflow: auto;
  height: calc(100vh - 150px);
  padding-right: 12px;
}

.tile-card-episode-wrapper,
.tile-card-video-wrapper,
.tile-card-clip-wrapper,
.tile-card-show-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
}

.tile-card-premiere-wrapper {
  position: relative;
  max-width: 100%;
  width: 300px;
  height: 150px;
  max-height: 150px;
  margin: 0 auto;
}

.tile-card-premiere-col-wrapper {
  padding-right: 18px;
}

.tile-card-premiere-img-wrapper {
  object-fit: cover;
  border-radius: 5px;
  max-width: 100%;
  width: 100%;
  max-height: 150px;
  min-height: 150px;
}

.tile-card-premiere-text-wrapper {
  display: flex;
  flex-direction: column;
}

.tile-card-episode-wrapper .ant-tag,
.tile-card-video-wrapper .ant-tag,
.tile-card-clip-wrapper .ant-tag,
.tile-card-show-wrapper .ant-tag {
  position: absolute;
  right: 0;
  top: 10px;
}

@media (min-width: 991px) {
  .tile-card-episode-col-wrapper.ant-col-xl-6:nth-child(n + 5),
  .tile-card-video-col-wrapper.ant-col-xl-6:nth-child(n + 5),
  .tile-card-clip-col-wrapper.ant-col-xl-6:nth-child(n + 5),
  .tile-card-show-col-wrapper.ant-col-xl-6:nth-child(n + 5) {
    margin-top: 40px;
  }

  .tile-card-podcast-col-wrapper.ant-col-xl-4:nth-child(n + 7),
  .tile-card-podcastepisode-col-wrapper.ant-col-xl-4:nth-child(n + 7) {
    margin-top: 40px;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .tile-card-episode-col-wrapper.ant-col-xl-6:nth-child(n + 4),
  .tile-card-video-col-wrapper.ant-col-xl-6:nth-child(n + 4),
  .tile-card-clip-col-wrapper.ant-col-xl-6:nth-child(n + 4),
  .tile-card-show-col-wrapper.ant-col-xl-6:nth-child(n + 4) {
    margin-top: 40px;
  }

  .tile-card-podcast-col-wrapper.ant-col-xl-4:nth-child(n + 5),
  .tile-card-podcastepisode-col-wrapper.ant-col-xl-4:nth-child(n + 5) {
    margin-top: 40px;
  }
  .tile-card-podcast img,
  .tile-card-podcastepisode img {
    min-height: 130px !important;
    max-height: 130px !important;
  }
}

@media (max-width: 767px) {
  .tile-card-episode-col-wrapper.ant-col-xl-6:nth-child(n + 2),
  .tile-card-video-col-wrapper.ant-col-xl-6:nth-child(n + 2),
  .tile-card-clip-col-wrapper.ant-col-xl-6:nth-child(n + 2),
  .tile-card-show-col-wrapper.ant-col-xl-6:nth-child(n + 2) {
    margin-top: 40px;
    text-align: center;
  }

  .tile-card-podcast-col-wrapper.ant-col-xl-4:nth-child(n + 2),
  .tile-card-podcastepisode-col-wrapper.ant-col-xl-4:nth-child(n + 2) {
    margin-top: 40px;
    text-align: center;
  }
}

.tile-card-episode img,
.tile-card-video img,
.tile-card-clip img,
.tile-card-show img {
  max-width: 100%;
  border-radius: 5px;
  /* width: 300px; */
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.tile-details-episode,
.tile-details-video,
.tile-details-clip,
.tile-details-show {
  margin-left: 0.3em;
  text-align: left;
}

.tile-title-episode,
.tile-title-video,
.tile-title-clip,
.tile-title-show {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0;
  line-height: 1.5em;
  max-height: 4.5em;
}

.image-override-margin-btn {
  margin-top: 24px;
}

.tile-card-delete {
  position: absolute;
  right: 10px;
  z-index: 111;
  top: 8px;
}

.table-image {
  width: 68px;
  height: 40px;
  object-fit: cover;
}
.table-name {
  word-wrap: break-word;
  word-break: break-word;
  text-align: left;
}

.carousel-card-footer-wrapper {
  text-align: left;
  margin-left: 25px;
}

.carousel-card {
  border-radius: 5px 5px 0px 0px;
}

.page-change-btn-wrapper {
  margin-top: 10px;
  float: right;
}

#modular-page-form .ant-progress-outer {
  display: none;
}

#modular-page-form .d-none.ant-form-item {
  display: none;
}

#modular-page-form .mb-0.ant-form-item {
  margin-bottom: 0px;
}

.page-change-btn-wrapper{
  float: right;
  margin-top: 10px;
}


.tile-card-podcast-wrapper,
.tile-card-podcastepisode-wrapper {
  position: relative;
  max-width: 100%;
  width: 200px;
  margin: 0 auto;
}

.tile-card-podcast img,
.tile-card-podcastepisode img {
  max-width: 100%;
  width: 100%;
  max-height: 170px;
  min-height: 170px;
  object-fit: cover;
}

.tile-details-podcast,
.tile-details-podcastepisode {
  margin-left: 0.3em;
  text-align: left;
}

.tile-title-podcast,
.tile-title-podcastepisode {
  color: rgb(39, 40, 50);
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8em;
  margin-bottom: 0;
  line-height: 1.5em;
  max-height: 4.5em;
}

.tile-card-podcast-premiere-img-wrapper {
  max-width: 100%;
  width: 200px;
  min-width: 100%;
  max-height: 170px;
  min-height: 170px;
  object-fit: cover;
}

.tile-card-podcast-premiere-wrapper {
  position: relative;
  max-width: 100%;
  width: 200px;
  margin: 0 auto;
}

img.podcast-upload-image {
  max-width: 100%;
  width: 100%;
  max-height: 170px;
  min-height: 170px;
  object-fit: cover;
  border-radius: 0;
}

.modular-page-select-episode-filter {
  width: 250px !important;
}  